var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.editForm)?_c('div',{staticClass:"hyphen-input-dash"},[_c('h2',[_vm._v("Field Settings")]),_c('div',{staticClass:"hyphen-input-dash__header"},[_c('div',{staticClass:"text-hint"},[_c('span',{domProps:{"innerHTML":_vm._s(_setup.alert)}}),_c('span',[_vm._v("Single line text")])]),_c('p',[_vm._v(" A compact, user-friendly input box ideal for collecting concise responses such as names, amount, or any other short textual data. ")])]),_c('div',{staticClass:"hyphen-input-dash__content"},[_c(_setup.HyphenSelect,{attrs:{"label":"Input Type","placeholder":"Select a type","description":"Type of input you want to create","option-label":"label","option-value":"value","options":[
        {
          label: 'Text',
          value: 'text',
        },
        {
          label: 'Digits',
          value: 'digits',
        },
        {
          label: 'Number',
          value: 'number',
        },
        {
          label: 'Email',
          value: 'email',
        },
        {
          label: 'Money',
          value: 'money',
        },
        {
          label: 'URL',
          value: 'url',
        },
      ]},model:{value:(_setup.editForm.type),callback:function ($$v) {_vm.$set(_setup.editForm, "type", $$v)},expression:"editForm.type"}}),_c(_setup.HyphenInput,{attrs:{"id":"dash-input-label","placeholder":"Enter label","label":"Label","description":"Label of input you want to create"},model:{value:(_setup.editForm.label),callback:function ($$v) {_vm.$set(_setup.editForm, "label", $$v)},expression:"editForm.label"}}),_c(_setup.HyphenInput,{attrs:{"id":"dash-input-placeholder","placeholder":"Enter placeholder text","label":"Placeholder (optional)","description":"Placeholder of input you want to create"},model:{value:(_setup.editForm.placeholder),callback:function ($$v) {_vm.$set(_setup.editForm, "placeholder", $$v)},expression:"editForm.placeholder"}}),_c(_setup.HyphenInput,{attrs:{"id":"dash-input-description","placeholder":"Enter description text","label":"Description (optional)","description":"Description of input you want to create"},model:{value:(_setup.editForm.description),callback:function ($$v) {_vm.$set(_setup.editForm, "description", $$v)},expression:"editForm.description"}}),_c(_setup.HyphenInput,{attrs:{"id":"dash-input-value","placeholder":"Enter default value","label":"Value (optional)","type":_setup.editForm.type,"description":"Default value of input you want to create"},model:{value:(_setup.editForm.value),callback:function ($$v) {_vm.$set(_setup.editForm, "value", $$v)},expression:"editForm.value"}}),_c(_setup.HyphenSelect,{attrs:{"label":"Required","placeholder":"Select a type","description":"Is this field required?","option-label":"label","option-value":"value","options":[
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ]},model:{value:(_setup.editForm.required),callback:function ($$v) {_vm.$set(_setup.editForm, "required", $$v)},expression:"editForm.required"}})],1),_c('div',{staticClass:"hyphen-input-dash__action"},[_c('button',{staticClass:"cancel",on:{"click":_setup.cancel}},[_vm._v("Cancel")]),(_setup.canApply)?_c('button',{staticClass:"apply",class:{
        'apply--disabled': !_setup.canApply,
      },on:{"click":_setup.apply}},[_vm._v(" Apply ")]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }