<script setup>
// import FormRender from "./components/Render/FormRender.vue";
import { ref } from "vue";
import FormBuilder from "./components/Builder/FormBuilder.vue";
// import HyphenCheckbox from "./components/base/HyphenCheckbox.vue";
// import HyphenList from "./components/base/HyphenList.vue";
import HyphenTextarea from "./components/base/HyphenTextarea.vue";

const canReview = ref(false);
const showPreview = ref(false);

const previewState = (value) => {
  canReview.value = value;
};

const form = ref({
  name: "Form Name",
  description: "Form Description",
});

// const listoptions = ref([]);

// const file = ref(null);

// const networks = ref([
//   {
//     label: "AIRTEL",
//     value: "Airtel",
//   },
//   {
//     label: "MTN",
//     value: "MTN",
//   },
//   {
//     label: "GLO",
//     value: "GLO",
//   },
//   {
//     label: "9MOBILE",
//     value: "9MOBILE",
//   },
// ]);
// const selectedNetwork = ref(["GLO"]);

const schema = ref(null);
const fields = ref(null);
// const preSchema = {
//   type: "group",
//   properties: [
//     {
//       id: "ycr1698530111133532",
//       type: "list",
//       value: [],
//       label: "Column one-Column 2",
//       key: "column_one-column_2",
//       required: true,
//       locked: false,
//       attributes: {
//         column: 2,
//         maxRows: 5,
//       },
//       rowData: [
//         {
//           label: "Column one",
//           type: "input",
//           value: "",
//           attributes: {
//             type: "text",
//             placeholder: "placeholder 1",
//             description: "description 1",
//           },
//         },
//         {
//           label: "Column two",
//           type: "input",
//           value: "",
//           attributes: {
//             type: "",
//             placeholder: "placeholder 2",
//             description: "description 2",
//           },
//         },
//       ],
//       options: [],
//     },
//     {
//       id: "erxs1697583668623819",
//       key: "firstname",
//       type: "input",
//       label: "Firstname",
//       value: "",
//       locked: false,
//       required: true,
//       attributes: {
//         type: "text",
//         description: "First name of the person making this request",
//         placeholder: "Firstname",
//       },
//     },
//   ],
// };
//   [
//   {
//     type: "group",
//     id: "cjcndnuoe4844948448v838383",
//     properties: [
//       {
//         id: "qjni1696791801626",
//         type: "input",
//         label: "First name",
//         value: "",
//         key: "first_name",
//         required: true,
//         attributes: {
//           type: "text",
//           description: "your legal first name",
//           placeholder: "Enter your first name",
//         },
//       },
//       {
//         id: "4848484hdbndndndnd",
//         type: "input",
//         label: "Last name",
//         key: "last_name",
//         value: "",
//         required: true,
//         attributes: {
//           type: "text",
//           description: "your legal last name",
//           placeholder: "Enter your last name",
//         },
//       },
//     ],
//   },

//   {
//     type: "group",
//     id: "shsbksulfbefiefief",
//     properties: [
//       {
//         id: "qjni16967kk896911801626",
//         type: "input",
//         label: "Email",
//         key: "email",
//         value: "",
//         required: true,
//         attributes: {
//           type: "name",
//           description: "your email",
//           placeholder: "Enter your email",
//         },
//       },
//       {
//         id: "qjni16967kk93nd896911801626",
//         type: "input",
//         label: "Phone",
//         key: "phone",
//         value: "",
//         required: true,
//         attributes: {
//           type: "number",
//           description: "your phone number",
//           placeholder: "Enter your phone number",
//         },
//       },
//     ],
//   },
//   {
//     type: "group",
//     id: "shsbksulfbefiefief",
//     properties: [
//       {
//         id: "qjni16967kk896911801626",
//         type: "input",
//         label: "Address",
//         key: "address",
//         value: "",
//         required: true,
//         attributes: {
//           type: "name",
//           description: "your address",
//           placeholder: "Enter your address",
//         },
//       },
//     ],
//   },
// ]

const preSchema = [
  {
    type: "group",
    properties: [
      {
        id: "qjni169679138801626",
        type: "input",
        label: "email",
        value: "",
        key: "email",
        required: true,
        attributes: {
          type: "email",
          description: "email",
          placeholder: "email",
        },
      },
      {
        id: "qjni1696791801626",
        type: "input",
        label: "First name",
        value: "",
        key: "first_name",
        required: true,
        attributes: {
          type: "text",
          description: "your legal first name",
          placeholder: "Enter your first name",
        },
      },
      {
        type: "content",
        id: "345fndidodvn",
        value: `Some random text to show how interesting our form builder can be. You are free to add text within your form to describe a page, a field or the form in general. Text can be headline, sub-text, or body text. Text is limited to 500 characters (spaces included) or 120 words.`,
        required: true,
        attributes: {
          type: "text",
          words: 120,
          characters: 500,
        },
      },

      // {
      //   id: "bld16988271107782088",
      //   type: "file",
      //   value: "",
      //   label: "Upload doc",
      //   key: "upload_doc",
      //   required: true,
      //   locked: false,
      //   attributes: {
      //     placeholder: "+ Select File",
      //     description: "",
      //   },
      // },
    ],
  },
  {
    type: "group",
    properties: [
      {
        id: "qjni16967913801626",
        type: "input",
        label: "Last name",
        value: "",
        key: "last_name",
        required: true,
        attributes: {
          type: "text",
          description: "your legal last name",
          placeholder: "Enter your last name",
        },
      },
      {
        id: "bwusq17048367541542336",
        type: "table",
        value: [],
        label: "Premeir League",
        key: "premeir_league",
        required: true,
        locked: false,
        attributes: {
          description: "escription",
          editable: true,
          source: "form",
          choice: "hypn4mgpzjg1836",
          data: [
            {
              label: "Email",
              value: "email",
            },
            {
              label: "First Name",
              value: "first_name",
            },
            {
              label: "Last Name",
              value: "last_name",
            },
            {
              label: "Parents Full Name",
              value: "parents_full_name",
            },
          ],
        },
      },
    ],
  },
];

// watch(
//   () => fields.value,
//   (value) => {
//     console.log("form fields", JSON.stringify(value, null, 2));
//   },
//   { deep: true }
// );
</script>
<template>
  <div class="app-canvas">
    <div class="app-canvas__wrapper">
      <!-- <hyphen-checkbox
        id="bob"
        label="Upload options"
        v-model="selectedNetwork"
        :options="networks"
        option-label="label"
        option-value="value"
        description="Select your network"
      />

      selected network : {{ selectedNetwork }} -->

      <!-- <HyphenList id="blob" v-model="listoptions" :rows="5" /> -->

      <!-- <pre>
        {{ JSON.stringify(listoptions, null, 2) }}
      </pre> -->

      <div class="actions">
        <button @click="showPreview = !showPreview" v-if="canReview">
          {{ showPreview ? "Edit Form" : "Preview Form" }}
        </button>
      </div>

      <hyphen-textarea
        id="bob"
        label="Upload options"
        v-model="form.description"
        description="Select your network"
      />
      <!-- <FormRender :api="props.api" :id="props.id" /> -->
      <div class="form">
        <FormBuilder
          :details="form"
          :show-preview="showPreview"
          @canPreview="previewState"
          @schema="schema = $event"
          :pre-schema="preSchema"
          @fields="fields = $event"
          formId="hypn62yfz702613"
          hyphen-token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzA0ODMwODUxLCJleHAiOjE3MDc0MjI4NTF9.4UAHW0Y0Sm8QUnJBoSF3cd_uB1J2IUCXp7Xox_7dw4Y"
        />
      </div>
      <pre>
       <!-- {{ JSON.stringify(schema, null, 2) }} -->
     </pre>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-canvas {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f7f4;

  .actions {
    padding: 30px 0px;

    button {
      padding: 10px;
      cursor: pointer;
    }
  }

  &__wrapper {
    width: 100%;
    max-width: 600px;

    .form {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
