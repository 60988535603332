var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"hyphen-input",style:({ width: _vm.width })},[_c('label',{staticClass:"hyphen-input__label",attrs:{"for":_setup.props.id}},[_vm._v(_vm._s(_setup.props.label))]),_c('div',{staticClass:"hyphen-input__input-wrapper",style:({ backgroundColor: _vm.backgroundColor })},[_c('div',{staticClass:"input-prefix"}),((_setup.props.type === 'number' ||
        _setup.props.type === 'money' ||
        _setup.props.type === 'url' ||
        _setup.props.type === 'digits'
          ? 'text'
          : _setup.props.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.inputValue),expression:"inputValue"}],staticClass:"input-target",style:({ height: _vm.height, ..._vm.inputStyle }),attrs:{"id":_setup.props.id,"placeholder":_setup.props.placeholder,"required":_setup.props.required,"disabled":!_setup.props.editable,"type":"checkbox"},domProps:{"checked":Array.isArray(_setup.inputValue)?_vm._i(_setup.inputValue,null)>-1:(_setup.inputValue)},on:{"blur":_setup.formatValue,"change":function($event){var $$a=_setup.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_setup.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_setup.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_setup.inputValue=$$c}}}}):((_setup.props.type === 'number' ||
        _setup.props.type === 'money' ||
        _setup.props.type === 'url' ||
        _setup.props.type === 'digits'
          ? 'text'
          : _setup.props.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.inputValue),expression:"inputValue"}],staticClass:"input-target",style:({ height: _vm.height, ..._vm.inputStyle }),attrs:{"id":_setup.props.id,"placeholder":_setup.props.placeholder,"required":_setup.props.required,"disabled":!_setup.props.editable,"type":"radio"},domProps:{"checked":_vm._q(_setup.inputValue,null)},on:{"blur":_setup.formatValue,"change":function($event){_setup.inputValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.inputValue),expression:"inputValue"}],staticClass:"input-target",style:({ height: _vm.height, ..._vm.inputStyle }),attrs:{"id":_setup.props.id,"placeholder":_setup.props.placeholder,"required":_setup.props.required,"disabled":!_setup.props.editable,"type":_setup.props.type === 'number' ||
        _setup.props.type === 'money' ||
        _setup.props.type === 'url' ||
        _setup.props.type === 'digits'
          ? 'text'
          : _setup.props.type},domProps:{"value":(_setup.inputValue)},on:{"blur":_setup.formatValue,"input":function($event){if($event.target.composing)return;_setup.inputValue=$event.target.value}}}),_c('div',{staticClass:"input-suffix"})]),_c('div',{staticClass:"hyphen-input__description-text",class:{ 'hyphen-component-error ': _setup.canShowError }},[_vm._v(" "+_vm._s(_setup.canShowError ? _setup.collectFeedback.error.message : _setup.props.description)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }