<script setup>
import {
  defineProps,
  ref,
  reactive,
  watch,
  defineEmits,
  computed,
  onMounted,
} from "vue";

const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  value: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },

  height: {
    type: String,
    default: "150px",
  },
  width: {
    type: String,
    default: "100%",
  },
  description: {
    type: String,
    default: "",
  },
  backgroundColor: {
    type: String,
    default: "transparent",
  },
  required: {
    type: Boolean,
    default: false,
  },
  action: {
    type: String || null,
    default: null,
  },
  editable: {
    type: Boolean,
    default: true,
  },
});

const inputValue = ref("");

const showError = ref(false);

const collectFeedback = computed(() => {
  let error = {
    hasError: false,
    message: "",
  };

  if (!inputValue.value && props.required) {
    error.hasError = true;
    error.message = `${props.label} is required`;
  }

  return {
    error,
  };
});

const canShowError = computed(() => {
  return showError.value && collectFeedback.value.error.hasError;
});

const emit = defineEmits({
  input(val) {
    return val;
  },
  feedback(val) {
    return val;
  },
});

watch(
  inputValue,
  (val) => {
    emit("input", val);
    emit("feedback", collectFeedback.value);
  },
  { immediate: true }
);

watch(
  () => props.value,
  (val) => {
    if (val !== inputValue.value) {
      inputValue.value = val;
      emit("input");
    }
  },
  { immediate: true }
);

watch(
  () => props.action,
  (val) => {
    switch (val) {
      case "showError":
        showError.value = true;
        break;
      case "hideError":
        showError.value = false;
        break;
      default:
        showError.value = false;
        break;
    }
  },
  { immediate: true }
);

const signaturePad = ref(null);
const containerRef = ref(null);
const clear = ref(null);
let ctx = null;

const position = reactive({
  x: 0,
  y: 0,
});

let writingMode = false;

const getCursorPosition = (event) => {
  position.x = event.clientX - event.target.getBoundingClientRect().x;
  position.y = event.clientY - event.target.getBoundingClientRect().y;
  return [position.x, position.y];
};

const getSignature = () => {
  inputValue.value = signaturePad.value.toDataURL();
  return signaturePad.value.toDataURL();
};

const handlePointerDown = (event) => {
  writingMode = true;
  ctx.beginPath();
  getCursorPosition(event);
  ctx.moveTo(position.x, position.y);
  getSignature();
};

const handlePointerUp = () => {
  writingMode = false;
};

const handlePointerMove = (event) => {
  if (!writingMode) return;
  getCursorPosition(event);
  ctx.lineTo(position.x, position.y);
  ctx.stroke();
  getSignature();
};

const clearPad = () => {
  ctx.clearRect(0, 0, signaturePad.value.width, signaturePad.value.height);
  signaturePad.value.style.backgoundImage = "none";
  inputValue.value = "";
};

onMounted(() => {
  signaturePad.value.width = containerRef.value.clientWidth;
  signaturePad.value.height = containerRef.value.clientHeight;
  ctx = signaturePad.value.getContext("2d");
  ctx.lineWidth = 3;
  ctx.lineJoin = ctx.lineCap = "round";

  signaturePad.value.addEventListener("pointerdown", handlePointerDown, {
    passive: true,
  });
  signaturePad.value.addEventListener("pointerup", handlePointerUp, {
    passive: true,
  });
  signaturePad.value.addEventListener("pointermove", handlePointerMove, {
    passive: true,
  });

  signaturePad.value.addEventListener("pointerleave", handlePointerUp, {
    passive: true,
  });
});

watch(
  () => props.value,
  (val) => {
    if (val !== inputValue.value) {
      inputValue.value = val;

      if (val) {
        const img = new Image();
        img.src = val;
        img.onload = () => {
          ctx.drawImage(img, 0, 0);
        };
      }
    }
  },
  { immediate: true }
);
</script>

<template>
  <div
    class="hyphen-signature"
    onmousedown="return false"
    :class="{ activex: inputValue !== '' }"
  >
    <label class="hyphen-signature__label" :for="props.id">{{
      props.label
    }}</label>
    <div
      class="hyphen-signature__input-wrapper"
      ref="containerRef"
      :style="{ width: props.width, height: props.height }"
    >
      <canvas
        ref="signaturePad"
        :style="{
          backgroundColor: props.backgroundColor,
        }"
      ></canvas>

      <button v-if="inputValue" @click="clearPad" ref="clear">clear</button>
    </div>
    <div
      class="hyphen-signature__description-text"
      :class="{ 'hyphen-component-error ': canShowError }"
    >
      {{ canShowError ? collectFeedback.error.message : props.description }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hyphen-signature {
  box-sizing: border-box;
  font-family: Inter;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  margin-bottom: 10px;

  &.activex {
    margin-bottom: 30px;
  }

  .hyphen-component-error {
    color: #ff0000;
  }

  &__label {
    color: rgba(25, 40, 61, 0.8);
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
    display: block;
    margin-bottom: 6px;
  }

  &__input-wrapper {
    border-radius: 5px;
    background-color: transparent;

    canvas {
      border: 1.8px dashed #d9dee1;
      border-radius: inherit;
      display: block;
      border-radius: 15px;
      cursor: crosshair;
    }

    button {
      width: 60px;
      padding: 5px;
      margin-top: 5px;
      border: 1.8px solid #d9dee1;
      background-color: transparent;
      cursor: pointer;
      border-radius: 5px;
      transition: all 0.3s ease;
      color: rgba(25, 40, 61, 0.8);
    }
  }

  &__description-text {
    font-size: 12px;
    color: #797e86;
  }
}
</style>
