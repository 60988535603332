const componentsSchema = [
  {
    type: "group",
    properties: [],
  },
  {
    type: "input",
    id: "@id",
    key: "",
    label: "",
    value: "",
    required: true,
    attributes: {
      placeholder: "",
      type: "text",
      description: "",
      editable: true,
    },
  },
  {
    type: "sensitive",
    id: "@id",
    key: "",
    label: "",
    value: "",
    required: true,
    attributes: {
      placeholder: "",
      type: "bvn",
      description: "",
      editable: true,
    },
  },
  {
    type: "multi-line-input",
    id: "@id",
    key: "",
    label: "",
    value: "",
    required: true,
    attributes: {
      placeholder: "",
      description: "",
      maxLine: 5,
      defaultLine: 3,
      editable: true,
    },
  },
  {
    type: "content",
    id: "@id",
    key: "@key",
    label: "@label",
    value: "",
    required: false,
    attributes: {
      type: "text",
      words: 120,
      characters: 500,
      editable: true,
    },
  },
  {
    type: "file",
    id: "@id",
    label: "",
    key: "",
    value: "",
    required: true,
    attributes: {
      placeholder: "+ Select File",
      types: ["image", "pdf"],
      description: "",
      sizeLimit: 2,
      editable: true,
    },
  },
  {
    type: "signature",
    id: "@id",
    label: "",
    key: "",
    value: "",
    required: true,
    attributes: {
      placeholder: "",
      description: "",
      editable: true,
      penColor: "black",
      backgroundColor: "white",
    },
  },

  {
    type: "select",
    id: "@id",
    value: "",
    label: "",
    key: "",
    required: true,
    attributes: {
      placeholder: "",
      description: "",
      editable: true,
    },
    options: [],
  },
  {
    type: "list",
    id: "@",
    value: [],
    key: "",
    label: "",
    required: true,
    attributes: {
      column: 1,
      maxRows: 5,
      editable: true,
    },
    rowData: [
      {
        label: "",
        type: "input",
        value: "",
        attributes: {
          type: "text",
          placeholder: "",
          description: "",
        },
      },
      {
        label: "",
        type: "input",
        value: "",
        attributes: {
          type: "text",
          placeholder: "",
          description: "",
        },
      },
    ],
    options: [],
  },
  {
    type: "data",
    id: "@id",
    value: "",
    label: "",
    key: "",
    required: true,
    attributes: {
      placeholder: "",
      description: "",
      editable: true,
      source: "",
      choice: "",
      data: "",
    },
    options: [],
  },
  {
    type: "table",
    id: "@id",
    value: "",
    label: "",
    key: "",
    required: true,
    attributes: {
      description: "",
      editable: true,
      source: "",
      choice: "",
      data: "",
    },
    options: [],
  },
  {
    type: "checklist",
    id: "@id",
    value: [],
    label: "",
    key: "",
    required: true,
    attributes: {
      description: "",
      editable: true,
    },
    options: [],
  },
];

export default componentsSchema;
