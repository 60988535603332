<script setup>
import { defineProps, ref, watch } from "vue";
import HyphenInput from "../base/HyphenInput.vue";
import HyphenSensitive from "../base/HyphenSensitive.vue";
import HyphenTextarea from "../base/HyphenTextarea.vue";
import HyphenContent from "../base/HyphenContent.vue";
import HyphenFileInput from "../base/HyphenFileInput.vue";
import HyphenSignature from "../base/HyphenSignature.vue";
import HyphenSelect from "../base/HyphenSelect.vue";
import HyphenCheckList from "../base/HyphenCheckList.vue";
import HyphenData from "../base/HyphenData.vue";
import HyphenTable from "../base/HyphenTable.vue";
import HyphenList from "../base/HyphenList.vue";

import { drag } from "../../svgs.js";

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits({
  edit(val) {
    return val;
  },
});

const inputValue = ref({
  value: props.data.value || "",
  ...props.data,
});

watch(
  () => props.data,
  (val) => {
    inputValue.value = {
      value: val.value || "",
      ...val,
    };
  },
  { deep: true },
  { immediate: true }
);

const editC = () => {
  emit("edit", props.data);
};

const removeC = () => {
  emit("remove", props.data);
};
</script>
<template>
  <div class="hyphen-form-builder-row" v-if="props.data && props.data.type">
    <div class="hyphen-form-builder-row__action">
      <button
        v-if="!props.data.locked"
        :class="{
          selected: isSelected,
        }"
        @click="removeC"
      >
        remove
      </button>
      <button
        v-if="!props.data.locked"
        :class="{
          selected: isSelected,
        }"
        @click="editC"
      >
        edit
      </button>
    </div>
    <div class="hyphen-form-builder-row__component">
      <div class="hyphen-form-builder-row__component__content">
        <hyphen-input
          v-model="inputValue.value"
          v-if="props.data.type === 'input'"
          :id="props.data.id"
          background-color="#F6F8F8"
          :placeholder="data.attributes.placeholder"
          :label="props.data.label"
          :type="props.data.attributes.type"
          :description="props.data.attributes.description"
          :required="props.data.required"
        />
        <hyphen-sensitive
          v-model="inputValue.value"
          v-if="props.data.type === 'sensitive'"
          :id="props.data.id"
          background-color="#F6F8F8"
          :placeholder="data.attributes.placeholder"
          :label="props.data.label"
          :type="props.data.attributes.type"
          :description="props.data.attributes.description"
          :required="props.data.required"
        />

        <hyphen-textarea
          v-model="inputValue.value"
          v-if="props.data.type === 'multi-line-input'"
          :id="props.data.id"
          background-color="#F6F8F8"
          :label="props.data.label"
          :placeholder="data.attributes.placeholder"
          :max-line="props.data.attributes.maxLine"
          :default-line="props.data.attributes.defaultLine"
          :description="props.data.attributes.description"
          :required="props.data.required"
        />

        <hyphen-content
          :value="props.data.value"
          v-if="props.data.type === 'content'"
          :id="props.data.id"
          :type="props.data.attributes.type"
        />

        <hyphen-file-input
          v-model="inputValue.value"
          v-if="props.data.type === 'file'"
          :id="props.data.id"
          background-color="#F6F8F8"
          :placeholder="props.data.attributes.placeholder"
          :label="props.data.label"
          :types="props.data.attributes.types"
          :description="props.data.attributes.description"
          :required="props.data.required"
        />
        <hyphen-signature
          v-model="inputValue.value"
          v-if="props.data.type === 'signature'"
          :id="props.data.id"
          background-color="#F6F8F8"
          :placeholder="props.data.attributes.placeholder"
          :label="props.data.label"
          :description="props.data.attributes.description"
          :required="props.data.required"
          :types="props.data.attributes.types"
        />
        <hyphen-select
          v-model="inputValue.value"
          v-if="props.data.type === 'select'"
          :id="props.data.id"
          background-color="#F6F8F8"
          :placeholder="props.data.attributes.placeholder"
          :label="props.data.label"
          option-label="label"
          option-value="value"
          :options="props.data.options"
          :description="props.data.attributes.description"
          :required="props.data.required"
        />

        <hyphen-check-list
          v-if="props.data.type === 'checklist'"
          :label="props.data.label"
          :id="props.data.id"
          background-color="#F6F8F8"
          v-model="inputValue.value"
          option-label="label"
          option-value="value"
          :options="props.data.options"
          :description="props.data.attributes.description"
          :required="props.data.required"
        />

        <hyphen-data
          v-model="inputValue.value"
          v-if="props.data.type === 'data'"
          :id="props.data.id"
          background-color="#F6F8F8"
          :placeholder="props.data.attributes.placeholder"
          :label="props.data.label"
          option-label="label"
          option-value="value"
          :source="props.data.attributes.source"
          :choice="props.data.attributes.choice"
          :data="props.data.attributes.data"
          :description="props.data.attributes.description"
          :required="props.data.required"
        />

        <hyphen-table
          v-model="inputValue.value"
          v-if="props.data.type === 'table'"
          :id="props.data.id"
          background-color="#F6F8F8"
          :label="props.data.label"
          option-label="label"
          option-value="value"
          :source="props.data.attributes.source"
          :choice="props.data.attributes.choice"
          :data="props.data.attributes.data"
          :description="props.data.attributes.description"
          :required="props.data.required"
        />

        <hyphen-list
          v-model="inputValue.value"
          v-if="props.data.type === 'list'"
          :id="props.data.id"
          background-color="#F6F8F8"
          :columns="props.data.attributes.column"
          :max-rows="props.data.attributes.maxRows"
          :row-data="props.data.rowData"
          :options="props.data.options"
          :required="props.data.required"
        />
      </div>
      <button
        class="hyphen-form-builder-row__component__drag"
        v-html="drag"
      ></button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hyphen-form-builder-row {
  &__action {
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
    box-sizing: border-box;
    gap: 8px;

    button {
      border: none;
      background-color: transparent;
      font-family: Inter;
      font-size: 14px;
      cursor: pointer;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &:hover {
        text-decoration-line: underline;
      }

      &:first-child {
        color: #ff6a6a;

        &.selected {
          color: var(--body-text, rgba(25, 40, 61, 0.8));
        }
      }

      &:last-child {
        color: var(--body-text, rgba(25, 40, 61, 0.8));

        &.selected {
          color: #5b67ba;
        }
      }
    }
  }

  &__component {
    display: flex;

    &__content {
      flex: 1;
    }

    &__drag {
      margin-top: 6px;
      border: none;
      background-color: transparent;
      width: 40px;
      height: 40px;
      cursor: grab;
    }
  }
}
</style>
