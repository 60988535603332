import { render, staticRenderFns } from "./CheckListDash.vue?vue&type=template&id=019a4749&scoped=true&"
import script from "./CheckListDash.vue?vue&type=script&setup=true&lang=js&"
export * from "./CheckListDash.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./CheckListDash.vue?vue&type=style&index=0&id=019a4749&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "019a4749",
  null
  
)

export default component.exports