<script setup>
import { defineProps, ref, watch, defineEmits, computed } from "vue";
import { chevron } from "../../svgs.js";

const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  value: {
    default: null,
  },
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  width: {
    type: String,
    default: "auto",
  },
  height: {
    type: String,
    default: "auto",
  },
  backgroundColor: {
    type: String,
    default: "transparent",
  },
  description: {
    type: String,
    default: "",
  },
  inputStyle: {
    type: Object,
    default: () => {},
  },
  required: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    default: () => [],
  },
  optionLabel: {
    type: String,
    default: "",
  },
  optionValue: {
    type: String,
    default: "",
  },
  action: {
    type: String || null,
    default: null,
  },
  editable: {
    type: Boolean,
    default: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const inputValue = ref("");
const showValue = ref("");
const query = ref("");

const showError = ref(false);

const collectFeedback = computed(() => {
  let error = {
    hasError: false,
    message: "",
  };

  if (!inputValue.value && props.required) {
    error.hasError = true;
    error.message = `${props.label} is required`;
  }

  return {
    error,
  };
});

const showOptions = ref(false);

const content = ref(null);
const input = ref(null);

const canShowError = computed(() => {
  return showError.value && collectFeedback.value.error.hasError;
});

const emit = defineEmits({
  input(val) {
    return val;
  },
  feedback(val) {
    return val;
  },
  changed(val) {
    return val;
  },
});

const selectOption = (option) => {
  inputValue.value =
    typeof option === "object"
      ? option[props.optionValue]
        ? option[props.optionValue]
        : option
      : option;

  showValue.value =
    typeof option === "object"
      ? option[props.optionLabel]
        ? option[props.optionLabel]
        : option
      : option;
  showOptions.value = false;

  emit("changed", inputValue.value);
  query.value = "";
};

const setValues = (val) => {
  if (val !== inputValue.value) {
    // if typeof option is object find the option with the val and use the optionValue as the value

    const value = props.options.find((option) => {
      if (typeof option === "object") {
        return option[props.optionValue] === val;
      } else {
        return option === val;
      }
    });

    if (value) {
      selectOption(value);
    } else {
      // possibly an empty value
      inputValue.value = val;
      showValue.value = val;
    }
  }
};

watch(
  inputValue,
  (val) => {
    if (props.options.length > 0) {
      emit("input", val);
      emit("feedback", collectFeedback.value);
    }
  },
  { immediate: true }
);

// watch for input value changes
watch(
  () => props.value,
  (val) => {
    if (props.options.length > 0) {
      setValues(val);
    }
  },
  { immediate: true }
);

// incase options comes in later
watch(
  () => props.options,
  (val) => {
    if (val && val.length > 0) {
      setValues(props.value);
    }
  },
  { deep: true },
  { immediate: true }
);

watch(
  () => props.action,
  (val) => {
    switch (val) {
      case "showError":
        showError.value = true;
        break;
      case "hideError":
        showError.value = false;
        break;
      default:
        showError.value = false;
        break;
    }
  },
  { immediate: true }
);

watch(
  () => showOptions.value,
  (val) => {
    if (val) {
      document.addEventListener("click", (e) => {
        if (
          e?.target !== content?.value &&
          !content?.value?.contains(e?.target) &&
          e?.target !== input?.value &&
          !input?.value?.contains(e?.target)
        ) {
          showOptions.value = false;
        }
      });
    }
  }
);

const allOptions = computed(() => {
  if (!query.value) {
    return props.options;
  } else {
    return props.options.filter((option) => {
      if (typeof option === "object") {
        return option[props.optionLabel]
          .toLowerCase()
          .includes(query.value.toLowerCase());
      } else {
        return option.toLowerCase().includes(query.value.toLowerCase());
      }
    });
  }
});

const filter = (e) => {
  query.value = e.target.value;
};
</script>

<template>
  <div class="hyphen-select" :style="{ width: width }">
    <label class="hyphen-select__label" :for="props.id">{{
      props.label
    }}</label>
    <div>
      <div v-if="isLoading" class="hyphen-select__loader">
        <div class="loader"></div>
      </div>
      <div
        class="hyphen-select__input-wrapper"
        :style="{ backgroundColor: backgroundColor }"
      >
        <div class="input-prefix"></div>
        <input
          ref="input"
          @click="props.editable ? (showOptions = !showOptions) : null"
          v-model="showValue"
          class="input-target"
          :style="{ height: height, ...inputStyle }"
          :id="props.id"
          :placeholder="props.placeholder"
          :required="props.required"
          @input="filter"
        />

        <div class="input-suffix" v-html="chevron"></div>
      </div>
      <div
        ref="content"
        class="hyphen-select__content"
        :class="{
          show: showOptions,
        }"
      >
        <slot>
          <div
            v-for="(option, index) in allOptions"
            :key="index"
            class="hyphen-select__option"
            @click="selectOption(option)"
          >
            {{
              typeof option === "object"
                ? option[props.optionLabel]
                  ? option[props.optionLabel]
                  : option
                : option
            }}
          </div>
        </slot>
      </div>
    </div>
    <div
      class="hyphen-select__description-text"
      :class="{ 'hyphen-component-error': canShowError }"
    >
      {{ canShowError ? collectFeedback.error.message : props.description }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hyphen-select {
  display: flex;
  flex-direction: column;
  font-family: Inter;
  gap: 6px;
  box-sizing: border-box;
  position: relative;

  &__label {
    color: rgba(25, 40, 61, 0.8);
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
  }

  &__loader {
    height: 1px;
    width: 98.5%;
    margin: 0px auto;
    background-color: #d9dee1;
    border-radius: 5px;
    transform: translateY(200%);

    .loader {
      height: 100%;
      width: 100%;
      background-color: rgba(25, 40, 61, 0.8);
      border-radius: inherit;
      animation: loader 1s ease-in-out infinite;
    }

    @keyframes loader {
      0% {
        width: 0%;
      }
      50% {
        width: 50%;
      }
      100% {
        width: 100%;
      }
    }
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    border: 1.8px solid #d9dee1;
    border-radius: 5px;
    padding: 12px 0px;
    background-color: transparent;
    box-sizing: border-box;

    .input-prefix {
      margin-left: 8px;
    }

    .input-target {
      flex: 1;
      min-width: auto;
      outline-style: none;
      border-radius: inherit;
      background-color: inherit;
      padding: none !important;
      box-sizing: border-box !important;
      border: none;
      color: #666;
      font-size: 15px;
      cursor: pointer;
    }

    .input-suffix {
      margin-right: 8px;
    }
  }

  &__description-text {
    font-size: 12px;
    color: #797e86;
  }

  &__content {
    padding: 3px 0px;
    max-height: 200px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 99999;
    transform: translateY(-0%);
    position: absolute;
    border-radius: 0px 0px 5px 5px;
    overflow-y: auto;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: none;

    &.show {
      opacity: 1;
      display: block;
    }
  }

  &__option {
    padding: 12px 16px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.hyphen-component-error {
  color: #ff0000;
}
</style>
