<script setup>
import { defineProps, ref, watch, defineEmits, computed } from "vue";

const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  value: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  width: {
    type: String,
    default: "auto",
  },
  height: {
    type: String,
    default: "auto",
  },
  backgroundColor: {
    type: String,
    default: "transparent",
  },
  description: {
    type: String,
    default: "",
  },
  inputStyle: {
    type: Object,
    default: () => {},
  },
  required: {
    type: Boolean,
    default: false,
  },
  action: {
    type: String || null,
    default: null,
  },
  editable: {
    type: Boolean,
    default: true,
  },
  autoFocus: {
    type: Boolean,
    default: false,
  },
});

const inputValue = ref("");

const convertToRaw = (value) => {
  return value.toString().replace(/,/g, "");
};

const addCommas = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const formatNumber = () => {
  let amount = convertToRaw(inputValue.value);
  if (!/^[0-9]+$/.test(amount)) {
    inputValue.value = "";
    return;
  }
  if (amount) {
    inputValue.value = addCommas(amount);
  }
};

const formatMoney = () => {
  let amount = inputValue.value;
  amount = convertToRaw(amount);

  if (!/^[0-9]+(\.[0-9]{1,2})?$/.test(amount)) {
    inputValue.value = "";
    return;
  }

  if (amount) {
    inputValue.value = addCommas(addKobo(amount));
  }
};

const addKobo = (target) => {
  let val = target;

  if (isNaN(parseFloat(target))) {
    return val;
  }

  // check if target ends with .
  if (target.endsWith(".")) {
    const newV = target + "00";
    val = newV.trim();
  } else if (target.indexOf(".") == -1) {
    const newV = target + ".00";
    val = newV.trim();
  } else {
    // already has dot
    const newV = parseFloat(target).toFixed(2);
    val = newV.trim();
  }

  // count number of dot in target
  const dotCount = (val.match(/\./g) || []).length;
  if (dotCount > 1) {
    // remove everything after the first dot
    val = val.slice(0, val.indexOf(".") + 1);
  }

  return val;
};

const formatValue = () => {
  if (props.type === "money") {
    // console.log("formatting money");
    formatMoney();
  } else if (props.type === "number") {
    formatNumber();
  }
};

const showError = ref(false);

const collectFeedback = computed(() => {
  let error = {
    hasError: false,
    message: "",
  };

  if (inputValue.value) {
    if (props.type === "number") {
      if (isNaN(parseFloat(convertToRaw(inputValue.value)))) {
        error.hasError = true;
        error.message = `${props.label} must be a valid number`;
      }
    } else if (props.type === "money") {
      if (isNaN(parseFloat(convertToRaw(inputValue.value)))) {
        error.hasError = true;
        error.message = `${props.label} must be a valid amount`;
      }
    } else if (props.type === "digits") {
      if (isNaN(parseFloat(inputValue.value))) {
        error.hasError = true;
        error.message = `${props.label} must be a valid number`;
      }
    } else if (props.type === "email") {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue.value)) {
        error.hasError = true;
        error.message = `${props.label} must be a valid email`;
      }
    } else if (props.type === "url") {
      if (!/^(ftp|http|https):\/\/[^ "]+$/.test(inputValue.value)) {
        error.hasError = true;
        error.message = `${props.label} must be a valid url`;
      }
    } else if (props.type === "date") {
      if (
        !/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/.test(
          inputValue.value
        )
      ) {
        error.hasError = true;
        error.message = `${props.label} must be in DD/MM/YYYY format`;
      }
    } else if (props.type === "text") {
      // also account for white space in text like for full name
      if (!/^[a-zA-Z0-9 ]+$/.test(inputValue.value)) {
        error.hasError = true;
        error.message = `${props.label} is invalid`;
      }
    }
  } else if (!inputValue.value && props.required) {
    error.hasError = true;
    error.message = `${props.label} is required`;
  }

  return {
    error,
  };
});

const canShowError = computed(() => {
  return showError.value && collectFeedback.value.error.hasError;
});

const emit = defineEmits({
  input(val) {
    return val;
  },
  feedback(val) {
    return val;
  },
});

watch(
  inputValue,
  (val) => {
    if (props.type === "money" || props.type === "number") {
      emit("input", convertToRaw(val));
    } else {
      emit("input", val);
    }

    emit("feedback", collectFeedback.value);
  },
  { immediate: true }
);

watch(
  () => props.value,
  (val) => {
    if (val !== inputValue.value) {
      inputValue.value = val;
      formatValue();
    }
  },
  { immediate: true }
);

watch(
  () => props.action,
  (val) => {
    switch (val) {
      case "showError":
        showError.value = true;
        break;
      case "hideError":
        showError.value = false;
        break;
      default:
        showError.value = false;
        break;
    }
  },
  { immediate: true }
);
</script>

<template>
  <div class="hyphen-input" :style="{ width: width }">
    <label class="hyphen-input__label" :for="props.id">{{ props.label }}</label>
    <div
      class="hyphen-input__input-wrapper"
      :style="{ backgroundColor: backgroundColor }"
    >
      <div class="input-prefix"></div>
      <input
        class="input-target"
        :style="{ height: height, ...inputStyle }"
        :id="props.id"
        v-model="inputValue"
        @blur="formatValue"
        :type="
          props.type === 'number' ||
          props.type === 'money' ||
          props.type === 'url' ||
          props.type === 'digits' ||
          props.type === 'date'
            ? 'text'
            : props.type
        "
        :placeholder="props.placeholder"
        :required="props.required"
        :disabled="!props.editable"
        :autofocus="props.autoFocus"
      />
      <div class="input-suffix"></div>
    </div>
    <div
      class="hyphen-input__description-text"
      :class="{ 'hyphen-component-error ': canShowError }"
    >
      {{ canShowError ? collectFeedback.error.message : props.description }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hyphen-input {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: Inter;
  gap: 6px;
  box-sizing: border-box;

  .hyphen-component-error {
    color: #ff0000;
  }

  &__label {
    color: rgba(25, 40, 61, 0.8);
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    border: 1.8px solid #d9dee1;
    border-radius: 5px;
    padding: 12px 0px;
    background-color: transparent;

    .input-prefix {
      margin-left: 8px;
    }

    input {
      padding: 0px;
      box-sizing: border-box;
    }

    .input-target {
      flex: 1;
      min-width: auto;
      outline-style: none;
      padding: 0px !important;
      box-sizing: border-box !important;
      border-radius: inherit;
      background-color: inherit;
      border: none;
      color: #666;
      font-size: 15px;
    }

    .input-suffix {
      margin-right: 8px;
    }
  }

  &__description-text {
    font-size: 12px;
    color: #797e86;
  }
}
</style>
