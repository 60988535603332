<script setup>
import { computed, defineProps, ref, watch } from "vue";
import { alert } from "../../../svgs.js";
import HyphenInput from "../../base/HyphenInput.vue";
import HyphenTextarea from "../../base/HyphenTextarea.vue";
import HyphenSelect from "../../base/HyphenSelect.vue";
import HyphenFileInput from "../../base/HyphenFileInput.vue";

const props = defineProps({
  form: {
    type: Object,
    default: null,
  },
});

const editForm = ref({
  type: "",
  value: "",
  label: "",
  placeholder: "",
  description: "",
  required: false,
});

const emit = defineEmits({
  edit(val) {
    return val;
  },
  remove(val) {
    return val;
  },
  cancelEdit(val) {
    return val;
  },
});

watch(
  () => props.form,
  (val) => {
    if (val) {
      editForm.value = {
        type: val.attributes.type,
        value: val.value,
        label: val.label,
        key: val.key,
        words: val.attributes.words,
        characters: val.attributes.characters,
        required: val.required.toString(),
      };
    }
  },
  { immediate: true }
);

const canApply = computed(() => {
  return editForm.value.value;
});

const apply = () => {
  const data = {
    id: props.form.id,
    type: props.form.type,
    value: editForm.value.value,
    label: editForm.value.label,
    key: editForm.value.key,
    required: editForm.value.required === "true" ? true : false,
    locked: props.form.locked || false,
    attributes: {
      type: editForm.value.type,
      words: editForm.value.words,
      characters: editForm.value.characters,
      editable: props.form.attributes.editable ?? true,
    },
  };
  emit("edit", data);
};

const cancel = () => {
  if (!props.form.value) {
    emit("remove", props.form);
  } else {
    emit("cancelEdit", props.form);
  }
};
</script>
<template>
  <div v-if="editForm" class="hyphen-content-dash">
    <h2>Field Settings</h2>
    <div class="hyphen-content-dash__header">
      <div class="text-hint">
        <span v-html="alert"></span><span>Content</span>
      </div>
      <p>
        A content display field, allows anyone to show a block of text within a
        form. Content can be headline text, sub-headline text, body text or
        image.
      </p>
    </div>

    <div class="hyphen-content-dash__content">
      <hyphen-select
        v-model="editForm.type"
        label="Content Type"
        placeholder="Select a type"
        description="Type of input you want to create"
        option-label="label"
        option-value="value"
        :options="[
          {
            label: 'Text',
            value: 'text',
          },
          {
            label: 'Headline',
            value: 'headline',
          },
          {
            label: 'Sub headline',
            value: 'sub-headline',
          },
          {
            label: 'Image',
            value: 'image',
          },
        ]"
      />

      <hyphen-input
        v-if="editForm.type !== 'image' && editForm.type !== 'text'"
        v-model="editForm.value"
        id="dash-content-value"
        placeholder=""
        :label="
          editForm.type === 'headline'
            ? 'Headline content'
            : editForm.type === 'sub-headline'
            ? 'Sub headline content'
            : ''
        "
        type="text"
        description="Value of the content"
      />

      <hyphen-textarea
        v-if="editForm.type === 'text'"
        label="Text content"
        v-model="editForm.value"
        :default-line="5"
        description="Value of the content"
      />

      <hyphen-file-input
        v-model="editForm.value"
        id="content-image-input"
        v-if="editForm.type === 'image'"
        background-color="#F6F8F8"
        placeholder="Select File"
        label="Image content"
        :types="['image']"
      />
    </div>
    <div class="hyphen-content-dash__action">
      <button @click="cancel" class="cancel">Cancel</button>
      <button
        @click="apply"
        v-if="canApply"
        class="apply"
        :class="{
          'apply--disabled': !canApply,
        }"
      >
        Apply
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.hyphen-content-dash {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  h2 {
    color: var(--hyphen-Blue, #19283d);
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 175% */
  }

  &__header {
    padding: 20px;
    margin: 20px 0px;
    border-radius: 4px;
    background: #f8f9fa;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.03);

    .text-hint {
      display: flex;
      align-items: center;
      gap: 10px;

      & > span:nth-child(2) {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 3px;
      }
    }

    p {
      color: var(--body-text, rgba(25, 40, 61, 0.8));
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 183.333% */
    }
  }

  &__content {
    flex: 1;
    overflow: auto;
    gap: 30px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  &__content::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }
  &__content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
  &__content::-webkit-scrollbar-thumb {
    background-color: var(--hyphen-Blue, #19283d);
    border-radius: 10px;
  }

  &__action {
    box-sizing: border-box;
    display: flex;
    gap: 20px;
    padding: 10px 0px;
    margin-bottom: 40px;
    z-index: 99;

    button {
      flex: 1;

      padding: 15px 30px;
      border-radius: 4px;
      border: none;

      font-size: 16px;
      font-weight: 500;
      cursor: pointer;

      &.cancel {
        border-radius: 4px;
        background: var(--secondary-button, #f4f5f6);
      }

      &.apply {
        border-radius: 4px;
        color: #fff;
        background: var(--hyphen-Blue, #19283d);
      }
    }
  }
}
</style>
