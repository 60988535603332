var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.editForm)?_c('div',{staticClass:"hyphen-input-dash"},[_c('h2',[_vm._v("Field Settings")]),_c('div',{staticClass:"hyphen-input-dash__header"},[_c('div',{staticClass:"text-hint"},[_c('span',{domProps:{"innerHTML":_vm._s(_setup.alert)}}),_c('span',[_vm._v("Select Field")])]),_c('p',[_vm._v(" A single selection field, allows anyone to select from predefined options, ensuring consistent and error-free data collection. ")])]),_c('div',{staticClass:"hyphen-input-dash__content"},[_c(_setup.HyphenInput,{attrs:{"id":"dash-input-label","placeholder":"Select Label Text","label":"Label","description":"Label of select you want to create"},model:{value:(_setup.editForm.label),callback:function ($$v) {_vm.$set(_setup.editForm, "label", $$v)},expression:"editForm.label"}}),_c(_setup.HyphenInput,{attrs:{"id":"dash-input-placeholder","placeholder":"Enter placeholder text","label":"Placeholder (optional)","description":"Placeholder of input you want to create"},model:{value:(_setup.editForm.placeholder),callback:function ($$v) {_vm.$set(_setup.editForm, "placeholder", $$v)},expression:"editForm.placeholder"}}),_c(_setup.HyphenInput,{attrs:{"id":"dash-input-description","placeholder":"Enter description text","label":"Description (optional)","description":"Description of input you want to create"},model:{value:(_setup.editForm.description),callback:function ($$v) {_vm.$set(_setup.editForm, "description", $$v)},expression:"editForm.description"}}),_c(_setup.HyphenSelect,{attrs:{"label":"Required","placeholder":"Select a type","description":"Is this field required?","option-label":"label","option-value":"value","options":[
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ]},model:{value:(_setup.editForm.required),callback:function ($$v) {_vm.$set(_setup.editForm, "required", $$v)},expression:"editForm.required"}}),_c('div',[_c('label',{staticClass:"hyphen-input-dash__label"},[_vm._v("Select Options (dropdown)")]),_c('div',[_vm._l((_setup.editForm.options),function(input,index){return _c('div',{key:index},[_c('div',{staticClass:"hyphen-input",staticStyle:{"padding-top":"8px"}},[_c('div',{staticClass:"hyphen-input__input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.editForm.options[index].label),expression:"editForm.options[index].label"}],staticClass:"input-target",attrs:{"placeholder":`Option ${_setup.indexInWords(index)}`},domProps:{"value":(_setup.editForm.options[index].label)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_setup.editForm.options[index], "label", $event.target.value)}}}),_c('div',{staticClass:"input-suffix"},[_c('span',{staticClass:"icon-x",domProps:{"innerHTML":_vm._s(_setup.hoverIndex === index ? _setup.closeHover : _setup.close)},on:{"click":function($event){return _setup.removeInput(index)},"mouseenter":function($event){_setup.hoverIndex = index},"mouseleave":function($event){_setup.hoverIndex = -1}}})])])])])}),_c('span',{staticClass:"add-button",on:{"click":_setup.addInput}},[_vm._v("+ Add option")])],2)])],1),_c('div',{staticClass:"hyphen-input-dash__action"},[_c('button',{staticClass:"cancel",on:{"click":_setup.cancel}},[_vm._v("Cancel")]),(_setup.canApply)?_c('button',{staticClass:"apply",class:{
        'apply--disabled': !_setup.canApply,
      },on:{"click":_setup.apply}},[_vm._v(" Apply ")]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }