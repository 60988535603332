var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.editForm)?_c('div',{staticClass:"hyphen-signature-input-dash"},[_c('h2',[_vm._v("Signature Pad Settings")]),_c('div',{staticClass:"hyphen-signature-input-dash__header"},[_c('div',{staticClass:"text-hint"},[_c('span',{domProps:{"innerHTML":_vm._s(_setup.alert)}}),_c('span',[_vm._v("Signature Pad")])]),_c('p',[_vm._v(" A component for capturing signatures. Users can draw their signatures using the mouse or touch input on touch-enabled devices. ")])]),_c('div',{staticClass:"hyphen-signature-input-dash__content"},[_c(_setup.HyphenInput,{attrs:{"id":"dash-signature-label","placeholder":"Enter label","label":"Label","description":"Label of the signature pad field"},model:{value:(_setup.editForm.label),callback:function ($$v) {_vm.$set(_setup.editForm, "label", $$v)},expression:"editForm.label"}}),_c(_setup.HyphenInput,{attrs:{"id":"dash-signature-placeholder","placeholder":"Please sign here","label":"Placeholder (optional)","description":"Sign your name as proof of agreement"},model:{value:(_setup.editForm.placeholder),callback:function ($$v) {_vm.$set(_setup.editForm, "placeholder", $$v)},expression:"editForm.placeholder"}}),_c(_setup.HyphenInput,{attrs:{"id":"dash-signature-description","placeholder":"Enter description text","label":"Description (optional)","description":"Description of the signature pad field"},model:{value:(_setup.editForm.description),callback:function ($$v) {_vm.$set(_setup.editForm, "description", $$v)},expression:"editForm.description"}}),_c(_setup.HyphenSelect,{attrs:{"label":"Required","placeholder":"Select a type","description":"Is this field required?","option-label":"label","option-value":"value","options":[
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ]},model:{value:(_setup.editForm.required),callback:function ($$v) {_vm.$set(_setup.editForm, "required", $$v)},expression:"editForm.required"}})],1),_c('div',{staticClass:"hyphen-signature-input-dash__action"},[_c('button',{staticClass:"cancel",on:{"click":_setup.cancel}},[_vm._v("Cancel")]),(_setup.canApply)?_c('button',{staticClass:"apply",class:{ 'apply--disabled': !_setup.canApply },on:{"click":_setup.apply}},[_vm._v(" Apply ")]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }