<script setup>
import { defineProps, ref, watch, defineEmits, computed } from "vue";
import { eye, eyeslash } from "../../svgs.js";

const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  // value: {
  //   type: String,
  //   default: "",
  // },
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "bvn",
  },
  width: {
    type: String,
    default: "auto",
  },
  height: {
    type: String,
    default: "auto",
  },
  backgroundColor: {
    type: String,
    default: "transparent",
  },
  description: {
    type: String,
    default: "",
  },
  inputStyle: {
    type: Object,
    default: () => {},
  },
  required: {
    type: Boolean,
    default: false,
  },

  action: {
    type: String || null,
    default: null,
  },
  editable: {
    type: Boolean,
    default: true,
  },
});

const inputValue = ref("");
const showError = ref(false);
const visibility = ref(false);

const collectFeedback = computed(() => {
  let error = {
    hasError: false,
    message: "",
  };

  if (inputValue.value) {
    if (props.type === "nin") {
      // Validate NIN (National Identification Number)
      if (!/^\d{11}$/.test(inputValue.value)) {
        error.hasError = true;
        error.message = "Invalid NIN";
      }
    } else if (props.type === "ein") {
      // Validate EIN (Employer Identification Number)
      if (!/^\d{9}$/.test(inputValue.value)) {
        error.hasError = true;
        error.message = "Invalid EIN (9 digits)";
      }
    } else if (props.type === "ssn") {
      // Validate SSN (Social Security Number)
      if (!/^\d{3}-\d{2}-\d{4}$/.test(inputValue.value)) {
        error.hasError = true;
        error.message = "Invalid SSN format (xxx-xx-xxxx)";
      }
    } else if (props.type === "bvn") {
      // Validate BVN (Bank Verification Number)
      if (!/^\d{11}$/.test(inputValue.value)) {
        error.hasError = true;
        error.message = "Invalid BVN (11 digits)";
      }
    }
  } else if (!inputValue.value && props.required) {
    error.hasError = true;
    error.message = `${props.label} is required`;
  }

  return {
    error,
  };
});

const canShowError = computed(() => {
  return showError.value && collectFeedback.value.error.hasError;
});

const validateInput = () => {
  showError.value = true;
  emit("feedback", collectFeedback.value);
};

const toggleVisibility = () => {
  visibility.value = !visibility.value;
};

const emit = defineEmits({
  input(val) {
    return val;
  },
  feedback(val) {
    return val;
  },
});

watch(
  inputValue,
  (val) => {
    emit("input", val);
    emit("feedback", collectFeedback.value);
  },
  { immediate: true }
);

watch(
  () => props.value,
  (val) => {
    if (val !== inputValue.value) {
      inputValue.value = val;
    }
  },
  { immediate: true }
);

watch(
  () => props.action,
  (val) => {
    switch (val) {
      case "showError":
        showError.value = true;
        break;
      case "hideError":
        showError.value = false;
        break;
      default:
        showError.value = false;
        break;
    }
  },
  { immediate: true }
);
</script>

<template>
  <div class="hyphen-input" :style="{ width: width }">
    <label class="hyphen-input__label" :for="props.id">{{ props.label }}</label>
    <div
      class="hyphen-input__input-wrapper"
      :style="{ backgroundColor: backgroundColor }"
    >
      <div class="input-prefix"></div>
      <input
        class="input-target"
        :style="{ height: height, ...inputStyle }"
        :id="props.id"
        v-model="inputValue"
        :type="!visibility ? 'password' : 'text'"
        :placeholder="props.placeholder"
        :required="props.required"
        :disabled="!props.editable"
        @blur="validateInput"
        @focus="showError = false"
        @input="showError = false"
      />
      <div class="input-suffix">
        <span
          v-html="visibility ? eye : eyeslash"
          @click="toggleVisibility"
          class="icon"
        ></span>
      </div>
    </div>
    <div
      class="hyphen-input__description-text"
      :class="{ 'hyphen-component-error ': canShowError }"
    >
      {{ canShowError ? collectFeedback.error.message : props.description }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hyphen-input {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: Inter;
  gap: 6px;
  box-sizing: border-box;

  .hyphen-component-error {
    color: #ff0000;
  }

  &__label {
    color: rgba(25, 40, 61, 0.8);
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    border: 1.8px solid #d9dee1;
    border-radius: 5px;
    padding: 12px 0px;
    background-color: transparent;

    .input-prefix {
      margin-left: 8px;
    }

    .input-target {
      flex: 1;
      min-width: auto;
      outline-style: none;
      border-radius: inherit;
      background-color: inherit;
      border: none;
      color: #666;
      font-size: 15px;
    }

    .input-suffix {
      margin-right: 8px;
    }
  }

  &__description-text {
    font-size: 12px;
    color: #797e86;
  }
}
</style>
