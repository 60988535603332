var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.editForm)?_c('div',{staticClass:"hyphen-input-dash"},[_c('h2',[_vm._v("Field Settings")]),_c('div',{staticClass:"hyphen-input-dash__header"},[_c('div',{staticClass:"text-hint"},[_c('span',{domProps:{"innerHTML":_vm._s(_setup.alert)}}),_c('span',[_vm._v("List field")])]),_c('p',[_vm._v(" Allow users dynamically add or subtract input fields, ideal for collecting dynamic data where the number of entries varies. ")])]),_c('div',{staticClass:"hyphen-input-dash__content"},[_c(_setup.HyphenSelect,{attrs:{"label":"Numbe of columns","placeholder":"Select number of columns","option-label":"label","option-value":"value","options":[
        {
          label: '1',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
      ]},on:{"changed":_setup.changeColumn},model:{value:(_setup.editForm.column),callback:function ($$v) {_vm.$set(_setup.editForm, "column", $$v)},expression:"editForm.column"}}),_c(_setup.HyphenInput,{attrs:{"type":"digits","placeholder":"Maximum rows allowed","label":"Maximum rows allowed"},model:{value:(_setup.editForm.maxRows),callback:function ($$v) {_vm.$set(_setup.editForm, "maxRows", $$v)},expression:"editForm.maxRows"}}),_vm._l((_setup.editForm.rows),function(row,index){return _c('div',{key:index,staticClass:"hyphen-input-dash__row-settings"},[_c('div',{staticClass:"tt"},[_vm._v("Column "+_vm._s(_setup.numberToWords(index + 1)))]),_c(_setup.HyphenSelect,{attrs:{"label":"Input Type","placeholder":"Select a type","description":"Type of input you want to create","option-label":"label","option-value":"value","options":[
          {
            label: 'Text',
            value: 'text',
          },
          {
            label: 'Number',
            value: 'number',
          },
          {
            label: 'Email',
            value: 'email',
          },
          {
            label: 'Money',
            value: 'money',
          },
          {
            label: 'URL',
            value: 'url',
          },
        ]},model:{value:(_setup.editForm.rows[index].type),callback:function ($$v) {_vm.$set(_setup.editForm.rows[index], "type", $$v)},expression:"editForm.rows[index].type"}}),_c(_setup.HyphenInput,{attrs:{"id":"dash-input-label","placeholder":"Enter label","label":"Label","description":"Label of input you want to create"},model:{value:(_setup.editForm.rows[index].label),callback:function ($$v) {_vm.$set(_setup.editForm.rows[index], "label", $$v)},expression:"editForm.rows[index].label"}}),_c(_setup.HyphenInput,{attrs:{"id":"dash-input-placeholder","placeholder":"Enter placeholder text","label":"Placeholder (optional)","description":"Placeholder of input you want to create"},model:{value:(_setup.editForm.rows[index].placeholder),callback:function ($$v) {_vm.$set(_setup.editForm.rows[index], "placeholder", $$v)},expression:"editForm.rows[index].placeholder"}}),_c(_setup.HyphenInput,{attrs:{"id":"dash-input-description","placeholder":"Enter description text","label":"Description (optional)","description":"Description of input you want to create"},model:{value:(_setup.editForm.rows[index].description),callback:function ($$v) {_vm.$set(_setup.editForm.rows[index], "description", $$v)},expression:"editForm.rows[index].description"}})],1)})],2),_c('div',{staticClass:"hyphen-input-dash__action"},[_c('button',{staticClass:"cancel",on:{"click":_setup.cancel}},[_vm._v("Cancel")]),(_setup.canApply)?_c('button',{staticClass:"apply",class:{
        'apply--disabled': !_setup.canApply,
      },on:{"click":_setup.apply}},[_vm._v(" Apply ")]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }