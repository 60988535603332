<script setup>
import { computed, defineProps, ref, watch, set } from "vue";
import { alert } from "../../../svgs.js";
import HyphenInput from "../../base/HyphenInput.vue";
import HyphenSelect from "../../base/HyphenSelect.vue";
import numberToWords from "@/numberToWords.js";

const close = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="#D9DEE1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M18 6L6 18" />
    <path stroke="#D9DEE1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M6 6L18 18" />
  </svg>
`;
const closeHover = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 6L6 18" stroke="#FF6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 6L18 18" stroke="#FF6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const props = defineProps({
  form: {
    type: Object,
    default: null,
  },
});

const editForm = ref({
  column: 1,
  maxRows: 3,
  rows: [
    // {
    //   label: "Column 1",
    //   type: "input",
    //   value: "",
    //   attributes: {
    //     type: "text",
    //     placeholder: "",
    //     description: "this is a description for column 1",
    //   },
    // },
    // {
    //   label: "Column 2",
    //   type: "input",
    //   value: "",
    //   attributes: {
    //     type: "text",
    //     placeholder: "",
    //     description: "this is a description for column 2",
    //   },
    // },
  ],
});

const emit = defineEmits({
  edit(val) {
    return val;
  },
  remove(val) {
    return val;
  },
  cancelEdit(val) {
    return val;
  },
});

watch(
  () => props.form,
  (val) => {
    if (val) {
      editForm.value = {
        column: val.attributes.column,
        maxRows: val.attributes.maxRows.toString(),
        rows: val.rowData.map((row) => {
          return {
            label: row.label,
            type: row.type !== "select" ? row.attributes.type : "select",
            placeholder: row.attributes.placeholder,
            description: row.attributes.description,
            ...(row.type === "select" && {
              options: row.options ?? [
                {
                  label: "",
                },
              ],
            }),
          };
        }),
        required: val.required.toString(),
      };
    }
  },
  { immediate: true }
);

const canApply = computed(() => {
  // check all label in the rows array are not empty
  return editForm.value.rows.every((row) => row.label);
});

const changeColumn = (e) => {
  if (process.env.NODE_ENV !== "production") {
    console.log("change column length to", e);
  }

  if (e < editForm.value.rows.length) {
    editForm.value.rows = editForm.value.rows.slice(0, e);
  } else {
    for (let i = editForm.value.rows.length; i < e; i++) {
      editForm.value.rows.push({
        label: ``,
        placeholder: "",
        description: ``,
      });
    }
  }
};

const apply = () => {
  const data = {
    id: props.form.id,
    type: props.form.type,
    value: props.form.value,
    label: editForm.value.rows.map((row) => row.label).join("-"),
    key: key.value,
    required: editForm.value.required === "true" ? true : false,
    locked: props.form.locked || false,
    attributes: {
      column: editForm.value.column,
      maxRows: parseInt(editForm.value.maxRows),
      editable: props.form.attributes.editable ?? true,
    },
    rowData: editForm.value.rows.map((row) => {
      return {
        label: row.label,
        type: row.type !== "select" ? "input" : "select",
        value: "",
        attributes: {
          ...(row.type !== "select" && {
            type: row.type,
          }),
          placeholder: row.placeholder,
          description: row.description,
        },
        ...(row.type === "select" && {
          options: row.options
            ? row.options.map((option) => {
                return {
                  label: option.label,
                  value: option.label.toLowerCase().replace(/ /g, "_"),
                };
              })
            : [
                {
                  label: "",
                  value: "",
                },
              ],
        }),
      };
    }),
    options: props.form.options,
  };

  console.log("list dast dash: data", JSON.stringify(data, null, 2));

  emit("edit", data);
};

const key = computed(() => {
  // convert string to snake case
  return editForm.value.rows
    .map((row) => row.label)
    .join("-")
    .toLowerCase()
    .replace(/ /g, "_")
    .replace(/[^\w-]+/g, "");
});

// watch(
//   () => editForm.value,
//   (val) => {
//     console.log("rows", JSON.stringify(val, null, 2));
//   },
//   { deep: true }
// );

const hoverIndex = ref(-1);

const indexInWords = computed(() => {
  return (index) => {
    return numberToWords(index + 1);
  };
});

const optionTypeSelected = (index, type) => {
  if (type === "select") {
    if (!editForm.value.rows[index].options) {
      set(editForm.value.rows[index], "options", [{ label: "" }]);
    }
  } else {
    if (editForm.value.rows[index].options) {
      delete editForm.value.rows[index].options;
    }
  }
};

const addInput = (index) => {
  editForm.value.rows[index].options.push({
    label: "",
  });
};

const removeInput = (index, row) => {
  editForm.value.rows[row].options.splice(index, 1);
};

const cancel = () => {
  if (!props.form.label) {
    emit("remove", props.form);
  } else {
    emit("cancelEdit", props.form);
  }
};
</script>
<template>
  <div v-if="editForm" class="hyphen-list-dash">
    <h2>Field Settings</h2>
    <div class="hyphen-list-dash__header">
      <div class="text-hint">
        <span v-html="alert"></span><span>List field</span>
      </div>
      <p>
        Allow users dynamically add or subtract input fields, ideal for
        collecting dynamic data where the number of entries varies.
      </p>
    </div>

    <div class="hyphen-list-dash__content">
      <hyphen-select
        v-model="editForm.column"
        @changed="changeColumn"
        label="Number of columns"
        placeholder="Select number of columns"
        option-label="label"
        option-value="value"
        :options="[
          {
            label: '1',
            value: 1,
          },
          {
            label: '2',
            value: 2,
          },
          {
            label: '3',
            value: 3,
          },
        ]"
      />
      <hyphen-input
        v-model="editForm.maxRows"
        type="digits"
        placeholder="Maximum rows allowed"
        label="Maximum rows allowed"
      />
      <div
        class="hyphen-list-dash__row-settings"
        v-for="(row, index) in editForm.rows"
        :key="index"
      >
        <div class="tt">Column {{ numberToWords(index + 1) }}</div>
        <hyphen-select
          v-model="editForm.rows[index].type"
          label="Input Type"
          @changed="optionTypeSelected(index, $event)"
          placeholder="Select a type"
          description="Type of input you want to create"
          option-label="label"
          option-value="value"
          :options="[
            {
              label: 'Text',
              value: 'text',
            },
            {
              label: 'Number',
              value: 'number',
            },
            {
              label: 'Email',
              value: 'email',
            },
            {
              label: 'Money',
              value: 'money',
            },
            {
              label: 'URL',
              value: 'url',
            },
            {
              label: 'Select',
              value: 'select',
            },
          ]"
        />

        <hyphen-input
          v-model="editForm.rows[index].label"
          id="dash-input-label"
          placeholder="Enter label"
          label="Label"
          description="Label of input you want to create"
        />

        <!-- here are the inputs that take select options as key and value -->
        <div
          v-if="
            editForm.rows[index].type === 'select' &&
            editForm.rows[index].options
          "
        >
          <label class="hyphen-list-dash__label"
            >Select Options (dropdown)</label
          >
          <div>
            <div
              v-for="(input, sIndex) in editForm.rows[index].options"
              :key="sIndex"
            >
              <div class="hyphen-input" style="padding-top: 8px">
                <div class="hyphen-input__input-wrapper">
                  <input
                    class="input-target"
                    :placeholder="`Option ${indexInWords(sIndex)}`"
                    v-model="editForm.rows[index].options[sIndex].label"
                  />
                  <div class="input-suffix">
                    <span
                      class="icon-x"
                      @click="removeInput(sIndex, index)"
                      v-html="hoverIndex === index ? closeHover : close"
                      @mouseenter="hoverIndex = index"
                      @mouseleave="hoverIndex = -1"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            <span @click="addInput(index)" class="add-button"
              >+ Add option</span
            >
          </div>
        </div>

        <hyphen-input
          v-model="editForm.rows[index].placeholder"
          id="dash-input-placeholder"
          placeholder="Enter placeholder text"
          label="Placeholder (optional)"
          description="Placeholder of input you want to create"
        />

        <hyphen-input
          v-model="editForm.rows[index].description"
          id="dash-input-description"
          placeholder="Enter description text"
          label="Description (optional)"
          description="Description of input you want to create"
        />
      </div>
    </div>
    <div class="hyphen-list-dash__action">
      <button @click="cancel" class="cancel">Cancel</button>
      <button
        @click="apply"
        v-if="canApply"
        class="apply"
        :class="{
          'apply--disabled': !canApply,
        }"
      >
        Apply
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.hyphen-list-dash {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  h2 {
    color: var(--hyphen-Blue, #19283d);
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 175% */
  }

  &__header {
    padding: 20px;
    margin: 20px 0px;
    border-radius: 4px;
    background: #f8f9fa;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.03);

    .text-hint {
      display: flex;
      align-items: center;
      gap: 10px;

      & > span:nth-child(2) {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 3px;
      }
    }

    p {
      color: var(--body-text, rgba(25, 40, 61, 0.8));
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 183.333% */
    }
  }

  &__content {
    flex: 1;
    overflow: auto;
    gap: 30px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  &__row-settings {
    .tt {
      color: var(--body-text, rgba(25, 40, 61, 0.8));
      font-weight: 600;
    }
    gap: 30px;
    display: flex;
    flex-direction: column;
  }

  &__content::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }
  &__content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
  &__content::-webkit-scrollbar-thumb {
    background-color: var(--hyphen-Blue, #19283d);
    border-radius: 10px;
  }

  &__action {
    box-sizing: border-box;
    display: flex;
    gap: 20px;
    padding: 10px 0px;
    margin-bottom: 40px;
    z-index: 99;

    button {
      flex: 1;

      padding: 15px 30px;
      border-radius: 4px;
      border: none;

      font-size: 16px;
      font-weight: 500;
      cursor: pointer;

      &.cancel {
        border-radius: 4px;
        background: var(--secondary-button, #f4f5f6);
      }

      &.apply {
        border-radius: 4px;
        color: #fff;
        background: var(--hyphen-Blue, #19283d);
      }
    }
  }
  &__label {
    color: rgba(25, 40, 61, 0.8);
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    border: 1.8px solid #d9dee1;
    border-radius: 5px;
    padding: 12px 0px;
    background-color: transparent;

    .input-target {
      flex: 1;
      min-width: auto;
      outline-style: none;
      border-radius: inherit;
      background-color: inherit;
      border: none;
      color: #666;
      font-size: 15px;
    }

    .input-suffix {
      margin-right: 20px;
    }
  }
}

.hyphen-input__input-wrapper {
  display: flex;
  align-items: center;
  border: 1.8px solid #d9dee1;
  border-radius: 5px;
  padding: 12px 0px;
  background-color: transparent;

  .input-target {
    flex: 1;
    min-width: auto;
    outline-style: none;
    border-radius: inherit;
    background-color: inherit;
    border: none;
    color: #666;
    font-size: 15px;
    padding-left: 10px;
  }

  .input-suffix {
    margin-right: 20px;
    display: flex;
    align-items: center;
    width: 16px;
    height: 12px;
    padding-left: 10px;
    padding-left: 10px;

    .icon-x {
      font-size: 24px;
      color: rgba(217, 222, 225, 1);
      cursor: pointer;
      font-family: "inter";
      font-weight: 200;

      &:hover {
        color: red;
      }
    }
  }
}

.add-button {
  text-decoration: underline;
  color: #5e6978;
  font-size: 12px;
  font-family: "Inter";
  font-weight: 400;
  cursor: pointer;
  padding-top: 8px;
}

.input-target:focus::placeholder {
  color: transparent;
}
</style>
