<script setup>
import { defineProps, ref, watch, computed } from "vue";
import { file } from "../../svgs.js";
const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  value: {
    type: [Object, String],
    default: () => null,
  },
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "+ Drag or drop file here",
  },
  types: {
    type: Array,
    default: () => ["image", "pdf"],
  },
  sizeLimit: {
    type: Number, // MB
    default: 2.0,
  },
  width: {
    type: String,
    default: "auto",
  },
  height: {
    type: String,
    default: "55px",
  },
  backgroundColor: {
    type: String,
    default: "transparent",
  },
  description: {
    type: String,
    default: "",
  },
  inputStyle: {
    type: Object,
    default: () => {},
  },
  required: {
    type: Boolean,
    default: false,
  },
  action: {
    type: String || null,
    default: null,
  },
  editable: {
    type: Boolean,
    default: true,
  },
});

const inputValue = ref({
  name: "",
  size: "",
  type: "",
  file: null,
});

const fileResult = ref(null);

const showError = ref(false);

const finalValue = computed(() => {
  return {
    name: inputValue.value.name,
    size: inputValue.value.size,
    type: inputValue.value.type,
    file: fileResult.value,
  };
});

const collectFeedback = computed(() => {
  let error = {
    hasError: false,
    message: "",
  };

  if (finalValue.value && finalValue.value.file) {
    if (finalValue.value.size > parseFloat(props.sizeLimit)) {
      error.hasError = true;
      error.message = `File size must be less than ${props.sizeLimit} MB`;
    } else if (
      !acceptableFiles.value.includes(inputValue.value.type) &&
      finalValue.value
    ) {
      error.hasError = true;
      error.message = `File type must be ${props.types
        .join(", ")
        .toUpperCase()}`;
    }
  }

  if (
    (finalValue.value.file === null || finalValue.value.file === "") &&
    props.required
  ) {
    error.hasError = true;
    error.message = `${props.label} is required`;
  }

  return {
    error,
  };
});

const canShowError = computed(() => {
  return showError.value && collectFeedback.value.error.hasError;
});

const convertToMB = (size) => {
  return Math.round((size / 1024 / 1024) * 100) / 100;
};

const emit = defineEmits({
  input(val) {
    return val;
  },
  feedback(val) {
    return val;
  },
});

watch(
  () => props.action,
  (val) => {
    switch (val) {
      case "showError":
        showError.value = true;
        break;
      case "hideError":
        showError.value = false;
        break;
      default:
        showError.value = false;
        break;
    }
  },
  { immediate: true }
);

watch(
  () => fileResult.value,
  () => {
    if (JSON.stringify(finalValue.value) !== JSON.stringify(props.value)) {
      emit("input", finalValue.value);
      emit("feedback", collectFeedback.value);
    }
  },
  { deep: true },
  { immediate: true }
);

watch(
  () => collectFeedback.value,
  (val) => {
    emit("feedback", val);
  },
  { deep: true },
  { immediate: true }
);

const handleUpload = (e) => {
  const files = e.target.files;

  if (files) {
    inputValue.value.name = files[0].name;
    inputValue.value.size = convertToMB(files[0].size);
    inputValue.value.type = files[0].type;
  }
  const reader = new FileReader();
  reader.onload = (e) => {
    fileResult.value = e.target.result;
  };

  if (e.target.files.length > 0) {
    reader.readAsDataURL(e.target.files[0]);
  }
};

watch(
  () => props.value,
  (val) => {
    if (
      val &&
      typeof val === "object" &&
      JSON.stringify(val) !== JSON.stringify(inputValue.value)
    ) {
      inputValue.value = val;
    } else {
      inputValue.value = {
        name: "",
        size: "",
        type: "",
        file: null,
      };
    }
  },
  { immediate: true },
  { deep: true }
);

const fileGroups = ref([
  {
    name: "Image",
    key: "image",
    types: ["image/png", "image/jpg", "image/jpeg"],
  },
  {
    file: "PDF",
    key: "pdf",
    types: ["application/pdf"],
  },
]);

// take files allowed from props (types) and merge with fileGroups
const acceptableFiles = computed(() => {
  const allTypes = [];

  props.types.forEach((type) => {
    const found = fileGroups.value.find((group) => group.key === type);
    if (found) {
      allTypes.push(...found.types);
    }
  });

  return allTypes;
});
</script>

<template>
  <div class="hyphen-file-input" :style="{ width: width }">
    <label class="hyphen-file-input__label" :for="props.id"
      >{{ props.label }}
      <div
        class="hyphen-file-input__input-wrapper"
        :style="{ backgroundColor: backgroundColor }"
      >
        <div class="input-prefix"></div>
        <div
          class="file-input-target"
          :style="{ height: height, ...inputStyle }"
        >
          <template v-if="inputValue && inputValue.name">
            {{ inputValue.name }}
          </template>
          <template v-else>
            {{ props.placeholder }}
          </template>
        </div>

        <div class="input-suffix" v-html="file"></div>
      </div>
    </label>
    <input
      :id="props.id"
      type="file"
      @change="handleUpload"
      :accept="acceptableFiles"
      style="display: none"
    />
    <div
      class="hyphen-file-input__description-text"
      :class="{ 'hyphen-component-error ': canShowError }"
    >
      {{ canShowError ? collectFeedback.error.message : props.description }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hyphen-file-input {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: Inter;

  .hyphen-component-error {
    color: #ff0000;
  }

  &__label {
    color: rgba(25, 40, 61, 0.8);
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
  }

  &__input-wrapper {
    margin-top: 6px;
    display: flex;
    align-items: center;
    border: 1.8px dashed #d9dee1;
    border-radius: 5px;
    padding: 12px 0px;
    background-color: transparent;
    gap: 3px;
    box-sizing: border-box;

    .input-prefix {
      margin-left: 8px;
    }

    .file-input-target {
      flex: 1;
      box-sizing: border-box;
      min-width: auto;
      outline-style: none;
      border-radius: inherit;
      background-color: inherit;
      display: flex;
      align-items: center;
      cursor: pointer;

      color: #666;
      font-weight: 400;
      font-size: 15px;
    }

    .input-suffix {
      margin-right: 8px;
    }
  }

  &__description-text {
    margin-top: 6px;
    font-size: 12px;
    color: #797e86;
  }
}
</style>
