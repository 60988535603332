const add = `<svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 8H12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 12V4" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const subtract = `<svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 8H12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

const singleLine = `<svg width="28" height="15" viewBox="0 0 16 3" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="14.3333" height="1.74626" rx="0.873132" stroke="#19283D"/>
<rect x="1.37317" y="1.14453" width="3.20398" height="0.686566" rx="0.343283" fill="#19283D"/>
</svg>
`;

const multiLine = `<svg width="28" height="18" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="15" height="2" rx="1" stroke="#19283D"/>
<rect x="0.5" y="3.88281" width="15" height="1" rx="0.5" stroke="#19283D"/>
<rect x="1.43286" y="4.17969" width="3.34327" height="0.477611" rx="0.238805" fill="#19283D"/>
</svg>
`;

const fileUpload = `<svg width="22" height="18" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.69666 8.91797V6.41797" stroke="#19283D" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.91663 7.66797H5.41663" stroke="#19283D" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.6666 6.16797V9.16797C11.6666 11.168 11.1666 11.668 9.16663 11.668H4.16663C2.16663 11.668 1.66663 11.168 1.66663 9.16797V4.16797C1.66663 2.16797 2.16663 1.66797 4.16663 1.66797H4.91663C5.66663 1.66797 5.83163 1.88797 6.11663 2.26797L6.86663 3.26797C7.05663 3.51797 7.16663 3.66797 7.66663 3.66797H9.16663C11.1666 3.66797 11.6666 4.16797 11.6666 6.16797Z" stroke="#19283D" stroke-miterlimit="10"/>
</svg>
`;

const list = `<svg width="22" height="20" viewBox="0 0 17 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.239436" y="0.239436" width="11.7324" height="2.19718" rx="0.598589" stroke="#19283D" stroke-width="0.478872"/>
<rect x="13.169" y="0.239436" width="3.59154" height="2.19718" rx="0.598589" stroke="#19283D" stroke-width="0.478872"/>
<rect x="0.239436" y="2.63006" width="11.7324" height="2.19718" rx="0.598589" stroke="#19283D" stroke-width="0.478872"/>
<rect x="13.169" y="2.63006" width="3.59154" height="2.19718" rx="0.598589" stroke="#19283D" stroke-width="0.478872"/>
<rect x="0.239436" y="5.0285" width="11.7324" height="2.19718" rx="0.598589" stroke="#19283D" stroke-width="0.478872"/>
<rect x="13.169" y="5.0285" width="3.59154" height="2.19718" rx="0.598589" stroke="#19283D" stroke-width="0.478872"/>
</svg>
`;

const switchI = `<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="4.66667" cy="4.66667" r="4.16667" stroke="#19283D"/>
<circle cx="4.66681" cy="4.66681" r="2.59259" fill="#19283D"/>
</svg>
`;

// const multiLine = `<svg width="16" height="6" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg">
// <rect x="0.5" y="0.5" width="15" height="2" rx="1" stroke="#19283D"/>
// <rect x="0.5" y="3.88281" width="15" height="1" rx="0.5" stroke="#19283D"/>
// <rect x="1.43286" y="4.17969" width="3.34327" height="0.477611" rx="0.238805" fill="#19283D"/>
// </svg>
// `;

const drag = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5401 8.31063C18.8987 8.31063 20.0001 7.20925 20.0001 5.85062C20.0001 4.492 18.8987 3.39062 17.5401 3.39062C16.1814 3.39062 15.0801 4.492 15.0801 5.85062C15.0801 7.20925 16.1814 8.31063 17.5401 8.31063Z" stroke="#D9DEE1" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.46001 8.31063C7.81863 8.31063 8.92 7.20925 8.92 5.85062C8.92 4.492 7.81863 3.39062 6.46001 3.39062C5.10139 3.39062 4 4.492 4 5.85062C4 7.20925 5.10139 8.31063 6.46001 8.31063Z" stroke="#D9DEE1" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.5401 20.6114C18.8987 20.6114 20.0001 19.51 20.0001 18.1514C20.0001 16.7928 18.8987 15.6914 17.5401 15.6914C16.1814 15.6914 15.0801 16.7928 15.0801 18.1514C15.0801 19.51 16.1814 20.6114 17.5401 20.6114Z" stroke="#D9DEE1" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.46001 20.6114C7.81863 20.6114 8.92 19.51 8.92 18.1514C8.92 16.7928 7.81863 15.6914 6.46001 15.6914C5.10139 15.6914 4 16.7928 4 18.1514C4 19.51 5.10139 20.6114 6.46001 20.6114Z" stroke="#D9DEE1" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const edit = `<svg width="24" height="24" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z" stroke="#19283D" stroke-opacity="0.8" stroke-width="1.5"/>
<path d="M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z" stroke="#19283D" stroke-opacity="0.8" stroke-width="1.5"/>
<path d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z" stroke="#19283D" stroke-opacity="0.8" stroke-width="1.5"/>
</svg>
`;

const isEditing = `<svg width="24" height="24" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z" stroke="#5B67BA" stroke-width="1.5"/>
<path d="M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z" stroke="#5B67BA" stroke-width="1.5"/>
<path d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z" stroke="#5B67BA" stroke-width="1.5"/>
</svg>
`;

const alert = `<svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.01184 1.69922C5.08101 1.69922 3.51184 3.26839 3.51184 5.19922V6.88505C3.51184 7.24089 3.36017 7.78339 3.17934 8.08672L2.50851 9.20089C2.09434 9.88922 2.38017 10.6534 3.13851 10.9101C5.65267 11.7501 8.36517 11.7501 10.8793 10.9101C11.5852 10.6767 11.8943 9.84255 11.5093 9.20089L10.8385 8.08672C10.6635 7.78339 10.5118 7.24089 10.5118 6.88505V5.19922C10.5118 3.27422 8.93684 1.69922 7.01184 1.69922Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M8.09095 1.86781C7.91012 1.81531 7.72345 1.77448 7.53095 1.75115C6.97095 1.68115 6.43428 1.72198 5.93262 1.86781C6.10178 1.43615 6.52178 1.13281 7.01178 1.13281C7.50178 1.13281 7.92178 1.43615 8.09095 1.86781Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.76172 11.1172C8.76172 12.0797 7.97422 12.8672 7.01172 12.8672C6.53339 12.8672 6.09005 12.6689 5.77505 12.3539C5.46005 12.0389 5.26172 11.5955 5.26172 11.1172" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10"/>
</svg>
`;

const chevron = `<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.58887 6L8.58887 10L12.5889 6" stroke="#111111" stroke-miterlimit="10" stroke-linecap="square"/>
</svg>
`;

const select = `<svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.863281" width="14" height="2" rx="1" stroke="#19283D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.736317 3.94531C0.32966 3.94531 0 4.27497 0 4.68163V10.5722C0 10.9788 0.32966 11.3085 0.736316 11.3085H9.26368C9.67034 11.3085 10 10.9788 10 10.5722V4.68163C10 4.27497 9.67034 3.94531 9.26368 3.94531H0.736317ZM0.499987 5.60108C0.398323 5.60108 0.315907 5.68349 0.315907 5.78516C0.315907 5.88682 0.398323 5.96924 0.499987 5.96924H9.37499C9.47665 5.96924 9.55907 5.88682 9.55907 5.78516C9.55907 5.68349 9.47665 5.60108 9.37499 5.60108H0.499987ZM0.315907 7.62891C0.315907 7.52724 0.398323 7.44483 0.499987 7.44483H9.37499C9.47665 7.44483 9.55907 7.52724 9.55907 7.62891C9.55907 7.73057 9.47665 7.81299 9.37499 7.81299H0.499987C0.398323 7.81299 0.315907 7.73057 0.315907 7.62891ZM0.499987 9.28467C0.398323 9.28467 0.315907 9.36709 0.315907 9.46875C0.315907 9.57041 0.398323 9.65283 0.499987 9.65283H9.37499C9.47665 9.65283 9.55907 9.57041 9.55907 9.46875C9.55907 9.36709 9.47665 9.28467 9.37499 9.28467H0.499987Z" fill="#19283D"/>
</svg>
`;

const close = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 6L6 18" stroke="#D9DEE1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 6L18 18" stroke="#D9DEE1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
const file = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#CCCCCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 9.51172L12 6.51172L15 9.51172" stroke="#CCCCCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 6.51172V14.5117" stroke="#CCCCCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 16.5117C9.89 17.8117 14.11 17.8117 18 16.5117" stroke="#CCCCCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const page = `<svg width="24" height="24" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.449331" y="0.449331" width="5.82882" height="7.96938" rx="1.34799" stroke="#19283D" stroke-width="0.898663"/>
<rect x="8.40001" y="0.449331" width="5.82882" height="7.96938" rx="1.34799" stroke="#19283D" stroke-width="0.898663"/>
</svg>
`;

const table = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#19283D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.03 8.5H22" stroke="#19283D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.03 15.5H22" stroke="#19283D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.51 21.99V2.01001" stroke="#19283D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.51 21.99V2.01001" stroke="#19283D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const eye = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#19283D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-eye"><path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"/><circle cx="12" cy="12" r="3"/></svg>`;

const eyeslash = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#19283D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-eye-off"><path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"/><path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"/><path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"/><line x1="2" x2="22" y1="2" y2="22"/></svg>`;

export {
  add,
  subtract,
  singleLine,
  multiLine,
  fileUpload,
  list,
  switchI,
  drag,
  edit,
  isEditing,
  alert,
  chevron,
  select,
  close,
  file,
  page,
  table,
  eye,
  eyeslash,
};
