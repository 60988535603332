var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.editForm)?_c('div',{staticClass:"hyphen-table-dash"},[_c('h2',[_vm._v("Field Settings")]),_c('div',{staticClass:"hyphen-table-dash__header"},[_c('div',{staticClass:"text-hint"},[_c('span',{domProps:{"innerHTML":_vm._s(_setup.alert)}}),_c('span',[_vm._v("Table Field")])]),_c('p',[_vm._v(" Table component allows you display columns and rows populated by submissions from an existing form. select the data source (form) and the data you want to display. ")])]),_c('div',{staticClass:"hyphen-table-dash__content"},[_c(_setup.HyphenInput,{attrs:{"id":"dash-input-label","placeholder":"Enter label text","label":"Table label","description":"Label of input you want to create"},model:{value:(_setup.editForm.label),callback:function ($$v) {_vm.$set(_setup.editForm, "label", $$v)},expression:"editForm.label"}}),_c(_setup.HyphenInput,{attrs:{"id":"dash-input-description","placeholder":"Enter description text","label":"Table Description (optional)","description":"Description of input you want to create"},model:{value:(_setup.editForm.description),callback:function ($$v) {_vm.$set(_setup.editForm, "description", $$v)},expression:"editForm.description"}}),_c(_setup.HyphenSelect,{attrs:{"label":"Data source","placeholder":"Select data source","description":"This is the data source","option-label":"label","option-value":"value","options":_setup.dataSource},on:{"changed":() => (_setup.editForm.choice = '')},model:{value:(_setup.editForm.source),callback:function ($$v) {_vm.$set(_setup.editForm, "source", $$v)},expression:"editForm.source"}}),(_setup.editForm.source)?_c(_setup.HyphenSelect,{attrs:{"is-loading":_setup.isFetchingFields,"label":`Choose ${
        _setup.editForm.source === 'form'
          ? 'form'
          : _setup.editForm.source === 'contacts'
          ? 'contact type'
          : 'external source'
      }`,"placeholder":"Select","description":"Make your choice","option-label":"label","option-value":"value","options":_setup.choiceOptions},on:{"changed":() => (_setup.editForm.data = '')},model:{value:(_setup.editForm.choice),callback:function ($$v) {_vm.$set(_setup.editForm, "choice", $$v)},expression:"editForm.choice"}}):_vm._e(),(_setup.editForm.choice)?_c(_setup.HyphenCheckList,{attrs:{"label":"Columns","options":_setup.dataOptions,"option-label":"label","description":"Select data columns"},model:{value:(_setup.editForm.data),callback:function ($$v) {_vm.$set(_setup.editForm, "data", $$v)},expression:"editForm.data"}}):_vm._e(),_c(_setup.HyphenSelect,{attrs:{"label":"Required","placeholder":"Select a type","description":"Is this field required?","option-label":"label","option-value":"value","options":[
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ]},model:{value:(_setup.editForm.required),callback:function ($$v) {_vm.$set(_setup.editForm, "required", $$v)},expression:"editForm.required"}})],1),_c('div',{staticClass:"hyphen-table-dash__action"},[_c('button',{staticClass:"cancel",on:{"click":_setup.cancel}},[_vm._v("Cancel")]),(_setup.canApply)?_c('button',{staticClass:"apply",class:{
        'apply--disabled': !_setup.canApply,
      },on:{"click":_setup.apply}},[_vm._v(" Apply ")]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }