<script setup>
import { defineProps, ref, watch, defineEmits, computed } from "vue";

const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  value: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  width: {
    type: String,
    default: "auto",
  },
  maxLine: {
    type: Number,
    default: 5,
  },
  defaultLine: {
    type: Number,
    default: 1,
  },
  backgroundColor: {
    type: String,
    default: "transparent",
  },
  description: {
    type: String,
    default: "",
  },
  inputStyle: {
    type: Object,
    default: () => {},
  },
  required: {
    type: Boolean,
    default: false,
  },
  action: {
    type: String || null,
    default: null,
  },
  editable: {
    type: Boolean,
    default: true,
  },
});

const inputValue = ref("");

const showError = ref(false);

const collectFeedback = computed(() => {
  let error = {
    hasError: false,
    message: "",
  };

  if (!inputValue.value && props.required) {
    error.hasError = true;
    error.message = `${props.label} is required`;
  }

  return {
    error,
  };
});

const canShowError = computed(() => {
  return showError.value && collectFeedback.value.error.hasError;
});

const emit = defineEmits({
  input(val) {
    return val;
  },
  feedback(val) {
    return val;
  },
});

watch(
  inputValue,
  (val) => {
    emit("input", val);
    emit("feedback", collectFeedback.value);
  },
  { immediate: true }
);

watch(
  () => props.value,
  (val) => {
    if (val !== inputValue.value) {
      inputValue.value = val;
    }
  },
  { immediate: true }
);

watch(
  () => props.action,
  (val) => {
    switch (val) {
      case "showError":
        showError.value = true;
        break;
      case "hideError":
        showError.value = false;
        break;
      default:
        showError.value = false;
        break;
    }
  },
  { immediate: true }
);

const inputTextarea = ref(null);

const resize = () => {
  // inputTextarea.value.style.height = "15px";
  // inputTextarea.value.style.height = `${Math.min(
  //   inputTextarea.value.scrollHeight,
  //   props.maxLine * 18
  // )}px`;

  // get number of lines by counting line breaks
  const getLineBreaks = (inputTextarea.value.value.match(/\n/g) || []).length;

  const [maxLine, minLine] = [props.maxLine, props.defaultLine];

  const numberOfLines = getLineBreaks + 1;
  const allowedLines =
    numberOfLines > maxLine
      ? maxLine
      : numberOfLines < minLine
      ? minLine
      : numberOfLines;

  inputTextarea.value.style.height = `${allowedLines * 18}px`;
};
</script>

<template>
  <div class="hyphen-textarea" :style="{ width: width }">
    <label class="hyphen-textarea__label" :for="props.id">{{
      props.label
    }}</label>
    <div
      class="hyphen-textarea__input-wrapper"
      :style="{ backgroundColor: backgroundColor }"
    >
      <div class="input-prefix"></div>
      <textarea
        ref="inputTextarea"
        class="input-target"
        :style="{ height: defaultLine * 18 + 'px', ...inputStyle }"
        :id="props.id"
        v-model="inputValue"
        :disabled="!props.editable"
        :placeholder="props.placeholder"
        :required="props.required"
        @input="resize"
      />
      <div class="input-suffix"></div>
    </div>
    <div
      class="hyphen-textarea__description-text"
      :class="{ 'hyphen-component-error ': canShowError }"
    >
      {{ canShowError ? collectFeedback.error.message : props.description }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hyphen-textarea {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: Inter;
  gap: 6px;
  box-sizing: border-box;

  .hyphen-component-error {
    color: #ff0000;
  }

  &__label {
    color: rgba(25, 40, 61, 0.8);
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    border: 1.8px solid #d9dee1;
    border-radius: 5px;
    padding: 12px 0px;
    background-color: transparent;

    .input-prefix {
      margin-left: 8px;
    }

    .input-target {
      flex: 1;
      min-width: auto;
      outline-style: none;
      overflow: hidden;
      border-radius: inherit;
      background-color: inherit;
      border: none;
      color: #666;
      font-size: 15px;
      font-family: "Inter";
      resize: none;
      height: 18px;
      line-height: 18px;
    }

    .input-suffix {
      margin-right: 8px;
    }
  }

  &__description-text {
    font-size: 12px;
    color: #797e86;
  }
}
</style>
