<script setup>
import {
  defineProps,
  ref,
  watch,
  defineEmits,
  onMounted,
  onUnmounted,
} from "vue";

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  closeable: {
    type: Boolean,
    default: true,
  },
});

const isActive = ref(false);
const isActiveUi = ref(false);

const emit = defineEmits({
  input(val) {
    return val;
  },
});
// listen to esc key press to close the drawer
onMounted(() => {
  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      hideDrawer();
    }
  });
});

// remove the event listener when the component is unmounted
onUnmounted(() => {
  window.removeEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      hideDrawer();
    }
  });
});

watch(
  () => props.value,
  (val) => {
    if (val) {
      isActive.value = true;
      setTimeout(() => {
        isActiveUi.value = true;
      }, 100);
    } else {
      isActiveUi.value = false;
      setTimeout(() => {
        isActive.value = false;
      }, 1000);
    }
  },
  { immediate: true }
);

const hideDrawer = () => {
  if (props.closeable) {
    emit("input", false);
  }
};
</script>
<template>
  <div class="invoice-details" v-if="isActive">
    <div
      class="invoice-details__overlay"
      @click="hideDrawer()"
      :class="{ show: isActiveUi }"
    ></div>

    <div class="invoice-details__body" :class="{ show: isActiveUi }">
      <slot> </slot>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.invoice-details {
  pointer-events: none;
  height: 0px;
  width: 0px;

  &__overlay {
    pointer-events: all;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    opacity: 0;

    background: black;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;

    &.show {
      opacity: 0.2;
    }
  }

  &__body {
    position: fixed;
    pointer-events: all;
    top: 0;
    right: 0;
    z-index: 9999;
    width: 400px;
    height: 100vh;
    background-color: white;
    transform: translateX(100%);

    // mobile
    @media (max-width: 768px) {
      width: 100%;
    }

    transition: transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);

    //shadow
    box-shadow: 0px 1px 16px rgba(66, 66, 66, 0.5);

    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(192, 196, 202, 0.15);

    &.show {
      transform: translateX(0%);
    }
  }
}
</style>
