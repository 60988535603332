export default function numberToWords(number) {
  // ... your numberToWords function code ...
  const units = [
    "Zero",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];

  const teens = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];

  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  const bigNumbers = ["", "Thousand", "Million", "Billion", "Trillion"];

  function convertToWords(num) {
    if (num < 10) {
      return units[num];
    } else if (num < 20) {
      return teens[num - 10];
    } else if (num < 100) {
      const ten = Math.floor(num / 10);
      const remainder = num % 10;
      return tens[ten] + (remainder ? " " + units[remainder] : "");
    } else {
      const hundred = Math.floor(num / 100);
      const remainder = num % 100;
      return (
        units[hundred] +
        " Hundred" +
        (remainder ? " " + convertToWords(remainder) : "")
      );
    }
  }

  if (number === 0) {
    return units[0];
  }

  let result = "";
  let chunkCount = 0;

  while (number > 0) {
    const chunk = number % 1000;
    if (chunk !== 0) {
      const chunkInWords = convertToWords(chunk);
      result =
        chunkInWords +
        (chunkCount > 0 ? " " + bigNumbers[chunkCount] : "") +
        (result ? " " + result : "");
    }
    number = Math.floor(number / 1000);
    chunkCount++;
  }

  return result;
}
