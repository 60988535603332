var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"hyphen-checkbox"},[_c('fieldset',{staticClass:"hyphen-checkbox__target"},[_c('legend',{staticClass:"hyphen-checkbox__label",attrs:{"for":_setup.props.id}},[_vm._v(" "+_vm._s(_setup.props.label)+" ")]),_c('div',{staticClass:"hyphen-checkbox__options"},_vm._l((_vm.options),function(option){return _c('label',{key:typeof option === 'object'
            ? option[_setup.props.optionLabel]
              ? option[_setup.props.optionLabel]
              : option
            : option,staticClass:"hyphen-checkbox__options--row"},[_vm._v(" "+_vm._s(typeof option === "object" ? option[_setup.props.optionLabel] ? option[_setup.props.optionLabel] : option : option)+" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.inputValue),expression:"inputValue"}],style:(_setup.props.inputStyle),attrs:{"type":"checkbox","id":typeof option === 'object'
              ? option[_setup.props.optionValue]
                ? option[_setup.props.optionValue]
                : option
              : option},domProps:{"value":typeof option === 'object'
              ? option[_setup.props.optionValue]
                ? option[_setup.props.optionValue]
                : option
              : option,"checked":Array.isArray(_setup.inputValue)?_vm._i(_setup.inputValue,typeof option === 'object'
              ? option[_setup.props.optionValue]
                ? option[_setup.props.optionValue]
                : option
              : option)>-1:(_setup.inputValue)},on:{"change":function($event){var $$a=_setup.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=typeof option === 'object'
              ? option[_setup.props.optionValue]
                ? option[_setup.props.optionValue]
                : option
              : option,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_setup.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_setup.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_setup.inputValue=$$c}}}}),_c('span',{staticClass:"checkmark"})])}),0)]),_c('div',{staticClass:"hyphen-checkbox__description-text",class:{ 'hyphen-component-error ': _setup.canShowError }},[_vm._v(" "+_vm._s(_setup.canShowError ? _setup.collectFeedback.error.message : _setup.props.description)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }