import { render, staticRenderFns } from "./listDash.vue?vue&type=template&id=0d52d78e&scoped=true&"
import script from "./listDash.vue?vue&type=script&setup=true&lang=js&"
export * from "./listDash.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./listDash.vue?vue&type=style&index=0&id=0d52d78e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d52d78e",
  null
  
)

export default component.exports