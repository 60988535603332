var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.editForm)?_c('div',{staticClass:"hyphen-content-dash"},[_c('h2',[_vm._v("Field Settings")]),_c('div',{staticClass:"hyphen-content-dash__header"},[_c('div',{staticClass:"text-hint"},[_c('span',{domProps:{"innerHTML":_vm._s(_setup.alert)}}),_c('span',[_vm._v("Content")])]),_c('p',[_vm._v(" A content display field, allows anyone to show a block of text within a form. Content can be headline text, sub-headline text, body text or image. ")])]),_c('div',{staticClass:"hyphen-content-dash__content"},[_c(_setup.HyphenSelect,{attrs:{"label":"Content Type","placeholder":"Select a type","description":"Type of input you want to create","option-label":"label","option-value":"value","options":[
        {
          label: 'Text',
          value: 'text',
        },
        {
          label: 'Headline',
          value: 'headline',
        },
        {
          label: 'Sub headline',
          value: 'sub-headline',
        },
        {
          label: 'Image',
          value: 'image',
        },
      ]},model:{value:(_setup.editForm.type),callback:function ($$v) {_vm.$set(_setup.editForm, "type", $$v)},expression:"editForm.type"}}),(_setup.editForm.type !== 'image' && _setup.editForm.type !== 'text')?_c(_setup.HyphenInput,{attrs:{"id":"dash-content-value","placeholder":"","label":_setup.editForm.type === 'headline'
          ? 'Headline content'
          : _setup.editForm.type === 'sub-headline'
          ? 'Sub headline content'
          : '',"type":"text","description":"Value of the content"},model:{value:(_setup.editForm.value),callback:function ($$v) {_vm.$set(_setup.editForm, "value", $$v)},expression:"editForm.value"}}):_vm._e(),(_setup.editForm.type === 'text')?_c(_setup.HyphenTextarea,{attrs:{"label":"Text content","default-line":5,"description":"Value of the content"},model:{value:(_setup.editForm.value),callback:function ($$v) {_vm.$set(_setup.editForm, "value", $$v)},expression:"editForm.value"}}):_vm._e(),(_setup.editForm.type === 'image')?_c(_setup.HyphenFileInput,{attrs:{"id":"content-image-input","background-color":"#F6F8F8","placeholder":"Select File","label":"Image content","types":['image']},model:{value:(_setup.editForm.value),callback:function ($$v) {_vm.$set(_setup.editForm, "value", $$v)},expression:"editForm.value"}}):_vm._e()],1),_c('div',{staticClass:"hyphen-content-dash__action"},[_c('button',{staticClass:"cancel",on:{"click":_setup.cancel}},[_vm._v("Cancel")]),(_setup.canApply)?_c('button',{staticClass:"apply",class:{
        'apply--disabled': !_setup.canApply,
      },on:{"click":_setup.apply}},[_vm._v(" Apply ")]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }