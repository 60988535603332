import Vue from "vue";
import App from "./App.vue";
// new Vue({ render: (h) => h(App) }).$mount("#app");

import HyphenFormRender from "./components/Render/FormRender.vue";
import HyphenFormBuilder from "./components/Builder/FormBuilder.vue";

export { HyphenFormRender, HyphenFormBuilder };

import vueCustomElement from "vue-custom-element";

Vue.use(vueCustomElement);
Vue.customElement("hyphen-sdk", App);
Vue.customElement("hyphen-form-render", HyphenFormRender);
Vue.customElement("hyphen-form-builder", HyphenFormBuilder);
