<script lang="ts" setup>
import { computed, ref, shallowRef, watch, watchEffect, onMounted } from "vue";
import FormRow from "./FormRenderRow.vue";

const props = defineProps({
  schema: {
    type: Array,
    default: () => null,
  },
  details: {
    type: Object,
    default: () => null,
  },

  isSubmitting: {
    type: Boolean,
    default: false,
  },
  isPreview: {
    type: Boolean,
    default: false,
  },
  hyphenToken: {
    type: String,
    default: "",
  },
  hyphenBaseUrl: {
    type: String,
    default: "https://api.onpbot.com/v1",
  },
  formId: {
    type: String,
    default: "",
  },
});

const emit = defineEmits({
  submit(val) {
    return val;
  },
});

const currentPage = ref(0);

const pagePercentage = computed(() => {
  if (!Array.isArray(formRenderStructure.value)) return 0;
  return ((currentPage.value + 1) / formRenderStructure.value.length) * 100;
});

const pageIsMoreThanOne = computed(() => {
  if (!Array.isArray(formRenderStructure.value)) return false;
  return formRenderStructure.value.length > 1;
});

const isLastPage = computed(() => {
  if (!Array.isArray(formRenderStructure.value)) return false;
  return formRenderStructure.value.length === currentPage.value + 1;
});

// if next page is available
const hasNextPage = computed(() => {
  if (!Array.isArray(formRenderStructure.value)) return false;
  return formRenderStructure.value.length > currentPage.value + 1;
});

// if previous page is available
const hasPreviousPage = computed(() => {
  if (!Array.isArray(formRenderStructure.value)) return false;
  return currentPage.value > 0;
});

const pageIsEmpty = computed(() => {
  if (!Array.isArray(formRenderStructure.value)) return false;
  return formRenderStructure.value[currentPage.value].properties.length === 0;
});

// move to the next or previous page
const moveForward = (e) => {
  if (e) {
    e.preventDefault();
  }

  if (!canSubmit.value && !props.isPreview) {
    formAction.value = "showError";
    return;
  }
  currentPage.value = currentPage.value + 1;
};

const moveBackward = (e) => {
  if (e) {
    e.preventDefault();
  }
  currentPage.value = currentPage.value - 1;
};

const formRenderStructure = shallowRef([]);

watch([currentPage, formRenderStructure], () => {
  if (Array.isArray(formRenderStructure.value)) {
    setActivePageData(currentPage.value);
  }
});

const pageView = ref({
  id: "",
  type: "",
  properties: [],
});

const setActivePageData = (Mindex = 0) => {
  if (process.env.NODE_ENV !== "production") {
    console.log(" active page is", Mindex);
  }

  pageView.value = formRenderStructure.value[Mindex];

  if (pageView.value && !props.isPreview) {
    pageView.value.properties.forEach((item, index) => {
      const orginalValue =
        formRenderStructure.value[Mindex].properties[index].value || "";

      //try set original value if no value has been set
      pageView.value.properties[index].value =
        payload.value[item.key] || orginalValue || "";
    });
  }
};

const formData = ref([
  {
    data: [],
  },
]);

const formAction = ref(null);

const getData = (val) => {
  // if the page does not exist in the form data, create it
  if (formData.value.length - 1 < currentPage.value) {
    formData.value.push({
      data: [],
    });
  }

  const excludedComponents = ["content"];

  if (
    val &&
    val.id &&
    currentPage.value !== -1 &&
    !excludedComponents.includes(val.type)
  ) {
    const index = formData.value[currentPage.value].data.findIndex(
      (item) => item.id === val.id
    );

    if (index !== -1) {
      formData.value[currentPage.value].data.splice(index, 1, {
        id: val.id,
        value: val.value,
        key: val.key,
        feedback: val.feedback,
      });
    } else {
      formData.value[currentPage.value].data.push({
        id: val.id,
        value: val.value,
        key: val.key,
        feedback: val.feedback,
      });
    }
  }
};

const payload = computed(() => {
  let payload = {};

  formData.value.forEach((page) => {
    page.data.forEach((item) => {
      if (item.key) {
        payload[item.key] = item.value;
      }
    });
  });

  return payload;
});

const canSubmit = computed(() => {
  let canSubmit = true;

  formData.value[currentPage.value].data.forEach((item) => {
    const feedback = item.feedback.error;
    if (
      feedback !== undefined &&
      feedback.hasError &&
      pageView.value.properties.find((prop) => prop.key === item.key)
    ) {
      canSubmit = false;
    }
  });

  return canSubmit;
});

watch(
  () => props.schema,
  (val) => {
    if (
      val &&
      JSON.stringify(val) !== JSON.stringify(formRenderStructure.value)
    ) {
      if (Array.isArray(val) && val.length > 0) {
        formRenderStructure.value = [];
        val.map((item) => {
          if (item.type === "group") {
            formRenderStructure.value.push({ ...item });
          }
        });
        return;
      }

      // if a type group object is passed
      if (typeof val === "object" && val.type === "group") {
        formRenderStructure.value = [
          {
            ...val,
          },
        ];
        return;
      }
    }
  },
  { immediate: true }
);

const submit = (e) => {
  if (e) {
    e.preventDefault();
  }

  if (canSubmit.value && !props.isPreview) {
    emit("submit", payload.value);
    if (process.env.NODE_ENV !== "production") {
      console.log("submitting form");
    }
  }

  if (!canSubmit.value) {
    formAction.value = "showError";
    if (process.env.NODE_ENV !== "production") {
      console.log("cannot submit form");
    }
  }
};

watchEffect(() => {
  if (process.env.NODE_ENV !== "production") {
    // console.log("schema", JSON.stringify(props.schema.value, null, 2));
    console.log(
      "form structure",
      JSON.stringify(formRenderStructure.value, null, 2)
    );
    console.log("form data", JSON.stringify(formData.value, null, 2));
    console.log("payload", JSON.stringify(payload.value, null, 2));
  }
});

const routeParams = ref(null);

const inputParams = computed(() => {
  if (!routeParams.value) return [];
  return routeParams.value
    .find((item) => item.type === "input")
    .value.split(",")
    .map((item) => {
      const [key, value] = item.split(":");
      return { key, value };
    });
});

onMounted(() => {
  try {
    const url = new URL(window.location.href);
    // decode the url params
    routeParams.value =
      decodeURIComponent(url.toString())
        .toString()
        .split("/")
        .pop()
        .split("?")[1]
        .split("&")
        .map((item) => {
          const [key, value] = item.split("=");
          return { type: key, value };
        }) || [];
  } catch (err) {
    console.log("");
  }

  // get the url params needed
  // ?input=first_name:overcomer,last_name:emiator&hide=first_name
});

const flatStructure = computed(() => {
  const data = [];
  formRenderStructure.value.map((item, index) => {
    if (item.type === "group" && item.properties.length > 0) {
      item.properties.map((i) => {
        data.push({
          ...i,
          groupIndex: index,
        });
      });
    }
  });

  return data;
});

watch(
  () => inputParams.value,
  (val) => {
    if (val.length > 0) {
      val.forEach((item) => {
        const flatIndex = flatStructure.value.findIndex(
          (i) => i.key === item.key
        );
        if (flatIndex !== -1) {
          const groupIndex = flatStructure.value[flatIndex].groupIndex;
          const group = formRenderStructure.value[groupIndex];
          const groupItemIndex = group.properties.findIndex(
            (i) => i.key === item.key
          );
          if (groupItemIndex !== -1) {
            formRenderStructure.value[groupIndex].properties[
              groupItemIndex
            ].value = item.value;
            formRenderStructure.value[groupIndex].properties[
              groupItemIndex
            ].attributes.editable = false;
          }
        }
      });
    }
  }
);

watch(
  () => props.hyphenToken,
  (val) => {
    if (val) {
      sessionStorage.setItem("hyphenToken", val);
    }
  },
  { immediate: true }
);

watch(
  () => props.hyphenBaseUrl,
  (val) => {
    if (val) {
      sessionStorage.setItem("hyphenBaseUrl", val);
    }
  },
  { immediate: true }
);

watch(
  () => props.formId,
  (val) => {
    if (val) {
      sessionStorage.setItem("formId", val);
    }
  },
  { immediate: true }
);
</script>
<template>
  <div class="hyphen-form-render">
    <div class="hyphen-form-render__header">
      <h3>
        {{ props.details.description || "N/A" }}
      </h3>
      <h1>{{ props.details.name || "N/A" }}</h1>
    </div>
    <div v-if="pageIsMoreThanOne" class="progress-bar">
      <div
        class="progress-bar__progress"
        :style="{ width: pagePercentage + '%' }"
      ></div>
    </div>
    <form @onsubmit="submit" class="hyphen-form-render__content">
      <form-row
        v-for="item in pageView.properties"
        :form="item"
        :key="item.id"
        :formAction="formAction"
        @update="getData"
      />
    </form>

    <div class="empty-pager" v-if="pageIsEmpty"></div>

    <div class="hyphen-form-render__action">
      <span v-if="!hasPreviousPage"></span>
      <button v-if="hasPreviousPage" @click="moveBackward">Previous</button>
      <button v-if="hasNextPage && !isLastPage" @click="moveForward">
        Next
      </button>
      <button
        v-if="isLastPage"
        @click="submit"
        :disabled="isSubmitting"
        :loading="isSubmitting"
      >
        Submit
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.hyphen-form-render {
  width: 100%;
  padding: 35px;
  font-family: Inter;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 20px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;

    h3 {
      color: var(--body-text, rgba(25, 40, 61, 0.8));
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: auto;
      margin: 0px;
      padding: 0px;
      display: block;
      margin-bottom: 15px;
    }

    h1 {
      font-size: 24px;
      font-weight: 700;
      color: #19283d;
      line-height: auto;
      margin: 0px;
      padding: 0px;
    }
  }

  .progress-bar {
    height: 4px;
    width: 100%;
    background-color: #e9ebf6;
    border-radius: 4px;
    margin-bottom: 20px;

    &__progress {
      transition: width 0.5s ease-in-out;
      height: 100%;
      border-radius: inherit;
      background: #5b67ba;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  &__action {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 25px;

    button {
      padding: 10px 30px;
      border-radius: 4px;
      border: none;
      background-color: #19283d;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &[loading] {
        // pulse animation
        animation: pulse 1.5s infinite;

        @keyframes pulse {
          0% {
            opacity: 0.5;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
</style>
