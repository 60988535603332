<script setup>
import {
  defineProps,
  ref,
  watch,
  defineEmits,
  computed,
  onMounted,
  reactive,
} from "vue";
import { getFormInputs, getContactEntries } from "@/api.js";
// import HyphenCheckBox from "./HyphenCheckBox.vue";

const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  value: {
    default: [],
  },
  width: {
    type: String,
    default: "auto",
  },
  height: {
    type: String,
    default: "auto",
  },
  backgroundColor: {
    type: String,
    default: "transparent",
  },
  description: {
    type: String,
    default: "",
  },
  inputStyle: {
    type: Object,
    default: () => {},
  },
  required: {
    type: Boolean,
    default: false,
  },
  optionLabel: {
    type: String,
    default: "",
  },
  optionValue: {
    type: String,
    default: "",
  },
  action: {
    type: String || null,
    default: null,
  },
  editable: {
    type: Boolean,
    default: true,
  },
  source: {
    type: String,
    default: "",
  },
  data: {
    type: Array,
    default: () => [],
  },
  choice: {
    type: String,
    default: "",
  },
});

//  "source": "form",
//       "choice": "hypn62yfz702613",
//       "data": "email"

const options = ref([]);

const isLoading = ref(false);

const inputValue = ref([]);

const showError = ref(false);

const canShowError = computed(() => {
  return showError.value && collectFeedback.value.error.hasError;
});

const collectFeedback = computed(() => {
  let error = {
    hasError: false,
    message: "",
  };

  if (inputValue.value.length === 0 && props.required) {
    error.hasError = true;
    error.message = `Select atleast one ${props.label} option in the table`;
  }

  return {
    error,
  };
});

const emit = defineEmits({
  input(val) {
    return val;
  },
  feedback(val) {
    return val;
  },
  changed(val) {
    return val;
  },
});

const paginationInfo = reactive({
  page: 0,
  totalPages: 0,
});

watch(
  inputValue,
  (val) => {
    emit("input", val);
    emit("feedback", collectFeedback.value);
  },
  { immediate: true }
);

watch(
  () => props.action,
  (val) => {
    switch (val) {
      case "showError":
        showError.value = true;
        break;
      case "hideError":
        showError.value = false;
        break;
      default:
        showError.value = false;
        break;
    }
  },
  { immediate: true }
);

const fetchFormsEntries = async () => {
  try {
    isLoading.value = true;
    if (props.source === "form") {
      const response = await getFormInputs({
        label: props.data.map((data) => data.value).join(","),
        category: props.choice,
        page: paginationInfo.page,
      });

      options.value = response.data.data;
      paginationInfo.page = response.data.page;
      paginationInfo.totalPages = response.data.totalPages;
    } else if (props.source === "contacts") {
      const response = await getContactEntries({
        label: props.data,
        category: props.choice,
      });

      options.value = response.data.data.map((data) => {
        return {
          label: data.label || "Unkwown",
          value: data.id,
        };
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

watch(
  () => props.data,
  (val) => {
    if (val) {
      fetchFormsEntries();
    }
  },
  { immediate: true }
);

const table = ref(null);
const container = ref(null);

const fetchNew = (page) => {
  paginationInfo.page = page;
  fetchFormsEntries();
};

const pageRange = computed(() => {
  const range = 2; // Adjust this value to limit the range
  let start = Math.max(1, paginationInfo.page - range);
  let end = Math.min(paginationInfo.totalPages, paginationInfo.page + range);

  if (start === 1) {
    end = Math.min(
      end + (range - paginationInfo.page + 1),
      paginationInfo.totalPages
    );
  } else if (end === paginationInfo.totalPages) {
    start = Math.max(
      start - (range - (paginationInfo.totalPages - paginationInfo.page)),
      1
    );
  }

  const pageRange = Array.from(
    { length: end - start + 1 },
    (_, i) => start + i
  );

  return pageRange.length > 0 ? pageRange : [];
});

const rowClick = (id) => {
  const index = inputValue.value.indexOf(id);
  if (index > -1) {
    inputValue.value.splice(index, 1);
  } else {
    inputValue.value.push(id);
  }
};

onMounted(() => {
  container.value.style.width = table.value.offsetWidth + "px";
  container.value.style.display = "flex";
});

watch(
  () => props.value,
  (val) => {
    if (
      Array.isArray(val) &&
      JSON.stringify(val) !== JSON.stringify(inputValue.value)
    ) {
      inputValue.value = val;
    }
  },
  { immediate: true },
  { deep: true }
);
</script>

<template>
  <div class="hyphen-table" ref="table" :style="{ width: width }">
    <label class="hyphen-select__label" :for="props.id">{{
      props.label
    }}</label>
    <div class="hyphen-table__body">
      <div v-if="props.description" class="hyphen-table-description">
        {{ props.description }}
      </div>
      <div class="hyphen-table-container" ref="container">
        <div v-if="!isLoading" class="t-actions">
          <div class="t-actions__head"></div>
          <div class="t-actions__body">
            <div class="a-item" v-for="i in options" :key="i">
              <label :id="i.id" class="hyphen-checkbox">
                <input
                  type="checkbox"
                  v-model="inputValue"
                  :id="i.id"
                  :value="i.id"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="t-wrapper" :class="{ loading: isLoading }">
          <table class="t-table">
            <thead>
              <tr class="t-table__head">
                <th v-for="tab in data" :key="tab">{{ tab.label }}</th>
              </tr>
            </thead>

            <tbody class="t-table__body" v-if="!isLoading">
              <tr
                class="ht-row"
                v-for="option in options"
                :key="option.id"
                @click="rowClick(option.id)"
              >
                <td v-for="x in Object.keys(option.labelData)" :key="x">
                  {{ option.labelData[x] || "N/A" }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="isLoading" class="loading-data">loading data...</div>
        </div>
      </div>
      <div v-if="pageRange.length > 1" class="hyphen-table-footer">
        <div class="pagination">
          <button @click="fetchNew(1)" v-if="paginationInfo.page > 1">
            first
          </button>
          <button
            v-if="paginationInfo.page > 1"
            @click="fetchNew(paginationInfo.page - 1)"
          >
            prev
          </button>
          <button
            v-for="page in pageRange"
            @click="fetchNew(page)"
            :key="page"
            :class="{ active: page === paginationInfo.page }"
          >
            {{ page }}
          </button>
          <button
            @click="fetchNew(paginationInfo.page + 1)"
            v-if="paginationInfo.page < paginationInfo.totalPages"
          >
            next
          </button>
          <button
            @click="fetchNew(paginationInfo.totalPages)"
            v-if="paginationInfo.page < paginationInfo.totalPages"
          >
            last
          </button>
        </div>
      </div>
    </div>

    <div v-if="canShowError" class="hyphen-table__error-text">
      {{ collectFeedback.error.message }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hyphen-table {
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  gap: 6px;
  width: 100%;
  box-sizing: border-box;

  &__label {
    color: rgba(25, 40, 61, 0.8);
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
  }

  &__body {
    border: 1.8px solid #d9dee1;
    border-radius: 5px;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
    .hyphen-table-description {
      padding: 26px;
      font-size: 12px;
      color: #797e86;
      color: #19283d;
      font-family: "Inter";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 183.333% */
    }

    .hyphen-table-container {
      display: none;
      width: inherit;
      overflow: auto;
      width: 100%;

      .t-actions {
        background-color: #fff;
        width: 42px;

        &__head {
          height: 42px;
          background: #d9dee1;
        }

        &__body {
          border-right: 1.8px solid #d9dee1;
          .a-item {
            height: 42px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 42px;

            //even
            &:nth-child(even) {
              background: #f6f8f8;
            }
          }
        }
      }

      .t-wrapper {
        width: calc(100% - 30px);
        box-sizing: border-box;
        overflow: auto;

        &.loading {
          width: 100%;
        }
      }
      .t-table {
        width: 100%;
        border-collapse: collapse;

        &__head {
          background: #d9dee1;
          padding: 5px;
          height: 42px;
          border-collapse: collapse;

          th {
            flex: 1;
            min-width: 120px;
            box-sizing: border-box;
            white-space: nowrap;
            color: rgba(25, 40, 61, 0.8);
            font-family: "Inter";
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0px;
            border-collapse: collapse;
            text-align: left;
            padding: 0px 15px;
            word-break: keep-all;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        &__body {
          tr {
            height: 42px;
            background-color: #fff;
            cursor: pointer;

            //even
            &:nth-child(even) {
              background: #f6f8f8;
            }

            td {
              min-width: 120px;
              max-width: 300px;
              padding: 0px 15px;
              box-sizing: border-box;
              color: #797e86;
              color: #19283d;
              font-family: "Inter";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 183.333% */
              margin: 0px;
              word-break: keep-all;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }

      .loading-data {
        display: block;
        width: 100%;
        margin: auto;
        box-sizing: border-box;
        padding: 80px 0px;
        font-family: "Inter";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #797e86;
        text-align: center;
      }
    }

    .hyphen-table-footer {
      padding: 13px;
      display: flex;
      justify-content: flex-end;

      .pagination {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        button {
          border: 1.8px solid #d9dee1;
          border-radius: 5px;
          background-color: #fff;
          color: #19283d;
          font-family: "Inter";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 5px 10px;
          margin: 0px 3px;
          cursor: pointer;

          &:hover {
            background-color: #d9dee1;
          }

          &.active {
            background-color: #19283d;
            color: #fff;
          }
        }
      }
    }
  }

  .hyphen-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
    color: rgba(25, 40, 61, 0.8);

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        background-color: var(--primary-color, #19283d);
      }

      &:hover ~ .checkmark {
        background-color: rgba(25, 40, 61, 0.7);
      }

      &:checked ~ .checkmark:after {
        display: block;
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 19px;
      width: 19px;
      border-radius: 3px;
      border: 1px solid #d9dee1;
      transform: translate(-10px, -10px);

      &:after {
        content: "";
        position: absolute;
        display: none;
      }

      &:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        border-radius: 2px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  &__error-text {
    font-size: 12px;
    color: #ff0000;
  }
}
</style>
