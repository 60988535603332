import axios from "axios";

const getForms = () => {
  return axios.get(`${sessionStorage.getItem("hyphenBaseUrl")}/forms`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("hyphenToken")}`,
    },
  });
};

const getFormEntries = ({ category, label }) => {
  return axios.get(
    `${sessionStorage.getItem(
      "hyphenBaseUrl"
    )}/workflows/formdata?category=${category}&label=${label}`
  );
};

const getFormInputs = ({ category, label, page = "1" }) => {
  return axios.get(
    `${sessionStorage.getItem(
      "hyphenBaseUrl"
    )}/workflows/formtable?category=${category}&label=${label}&page=${page.toString()}&pageSize=10`
  );
};

const getContactEntries = ({ category, label }) => {
  return axios.get(
    `${sessionStorage.getItem(
      "hyphenBaseUrl"
    )}/workflows/contact?category=${category}&label=${label}&id=${sessionStorage.getItem(
      "formId"
    )}`
  );
};

const verifyCode = (code) => {
  return axios.get(
    `${sessionStorage.getItem(
      "hyphenBaseUrl"
    )}organizations/confirminvite?otp=${code}`
  );
};

export {
  getForms,
  getFormEntries,
  getFormInputs,
  getContactEntries,
  verifyCode,
};
