<script lang="ts" setup>
import { defineProps, ref, watch, computed } from "vue";

const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  value: {
    type: Array,
    default: () => [],
  },
  label: {
    type: String,
    default: "",
  },
  width: {
    type: String,
    default: "auto",
  },
  height: {
    type: String,
    default: "auto",
  },
  backgroundColor: {
    type: String,
    default: "transparent",
  },
  description: {
    type: String,
    default: "",
  },
  inputStyle: {
    type: Object,
    default: () => {},
  },
  required: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    default: () => [],
  },
  optionLabel: {
    type: String,
    default: "",
  },
  optionValue: {
    type: String,
    default: "",
  },
  action: {
    type: String || null,
    default: null,
  },
  editable: {
    type: Boolean,
    default: true,
  },
});

const inputValue = ref([]);

const showError = ref(false);

const collectFeedback = computed(() => {
  let error = {
    hasError: false,
    message: "",
  };

  if (inputValue.value.length === 0 && props.required) {
    error.hasError = true;
    error.message = `Select atleast one ${props.label} option on the list`;
  }

  return {
    error,
  };
});

const canShowError = computed(() => {
  return showError.value && collectFeedback.value.error.hasError;
});

const emit = defineEmits({
  input(val) {
    return val;
  },
  feedback(val) {
    return val;
  },
});

watch(
  inputValue,
  (val) => {
    emit("input", val);
    emit("feedback", collectFeedback.value);
  },
  { immediate: true }
);

watch(
  () => props.value,
  (val) => {
    if (
      JSON.stringify(val) !== JSON.stringify(inputValue.value) &&
      Array.isArray(val)
    ) {
      inputValue.value = val;
    }
  },
  { immediate: true }
);

watch(
  () => props.action,
  (val) => {
    switch (val) {
      case "showError":
        showError.value = true;
        break;
      case "hideError":
        showError.value = false;
        break;
      default:
        showError.value = false;
        break;
    }
  },
  { immediate: true }
);
</script>
<template>
  <div class="hyphen-checkbox">
    <fieldset class="hyphen-checkbox__target">
      <legend class="hyphen-checkbox__label" :for="props.id">
        {{ props.label }}
      </legend>
      <div class="hyphen-checkbox__options">
        <label
          class="hyphen-checkbox__options--row"
          v-for="option in options"
          :key="
            typeof option === 'object'
              ? option[props.optionLabel]
                ? option[props.optionLabel]
                : option
              : option
          "
        >
          {{
            typeof option === "object"
              ? option[props.optionLabel]
                ? option[props.optionLabel]
                : option
              : option
          }}
          <input
            type="checkbox"
            v-model="inputValue"
            :id="
              typeof option === 'object'
                ? option[props.optionValue]
                  ? option[props.optionValue]
                  : option
                : option
            "
            :style="props.inputStyle"
            :value="
              typeof option === 'object'
                ? option[props.optionValue]
                  ? option[props.optionValue]
                  : option
                : option
            "
          />

          <span class="checkmark"></span>
        </label>
      </div>
    </fieldset>
    <div
      class="hyphen-checkbox__description-text"
      :class="{ 'hyphen-component-error ': canShowError }"
    >
      {{ canShowError ? collectFeedback.error.message : props.description }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hyphen-checkbox {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: Inter;
  gap: 6px;
  box-sizing: border-box;

  .hyphen-component-error {
    color: #ff0000;
  }

  &__label {
    color: rgba(25, 40, 61, 0.8);
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
    padding: 0 5px;
  }

  &__target {
    border: 1.8px solid #d9dee1;
    border-radius: 5px;
    padding: 12px;
  }

  &__options {
    padding: 6px 0;
    display: flex;
    flex-direction: column;
    gap: 6px;

    &--row {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      font-weight: 500;
      color: rgba(25, 40, 61, 0.8);

      &:last-child {
        margin-bottom: 0;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark {
          background-color: var(--primary-color, #19283d);
        }

        &:hover ~ .checkmark {
          background-color: rgba(25, 40, 61, 0.7);
        }

        &:checked ~ .checkmark:after {
          display: block;
        }
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 21px;
        width: 21px;
        background-color: #eee;
        border-radius: 3px;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }

        &:after {
          left: 6px;
          top: 3px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          border-radius: 2px;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }

  &__description-text {
    font-size: 12px;
    color: #797e86;
  }
}
</style>
