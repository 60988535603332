<script setup>
import { computed, defineProps, ref, watch } from "vue";
import { alert } from "../../../svgs.js";
import HyphenInput from "../../base/HyphenInput.vue";
import HyphenSelect from "../../base/HyphenSelect.vue";
import numberToWords from "@/numberToWords.js";

const props = defineProps({
  form: {
    type: Object,
    default: null,
  },
});

const editForm = ref({
  column: 1,
  maxRows: 3,
  rows: [
    // {
    //   label: "Column 1",
    //   type: "input",
    //   value: "",
    //   attributes: {
    //     type: "text",
    //     placeholder: "",
    //     description: "this is a description for column 1",
    //   },
    // },
    // {
    //   label: "Column 2",
    //   type: "input",
    //   value: "",
    //   attributes: {
    //     type: "text",
    //     placeholder: "",
    //     description: "this is a description for column 2",
    //   },
    // },
  ],
});

const emit = defineEmits({
  edit(val) {
    return val;
  },
  remove(val) {
    return val;
  },
  cancelEdit(val) {
    return val;
  },
});

watch(
  () => props.form,
  (val) => {
    if (val) {
      editForm.value = {
        column: val.attributes.column,
        maxRows: val.attributes.maxRows.toString(),
        rows: val.rowData.map((row) => {
          return {
            label: row.label,
            type: row.attributes.type,
            placeholder: row.attributes.placeholder,
            description: row.attributes.description,
          };
        }),
        required: val.required.toString(),
      };
    }
  },
  { immediate: true }
);

const canApply = computed(() => {
  // check all label in the rows array are not empty
  return editForm.value.rows.every((row) => row.label);
});

const changeColumn = (e) => {
  if (process.env.NODE_ENV !== "production") {
    console.log("change column length to", e);
  }

  if (e < editForm.value.rows.length) {
    editForm.value.rows = editForm.value.rows.slice(0, e);
  } else {
    for (let i = editForm.value.rows.length; i < e; i++) {
      editForm.value.rows.push({
        label: ``,
        placeholder: "",
        description: ``,
      });
    }
  }
};

const apply = () => {
  const data = {
    id: props.form.id,
    type: props.form.type,
    value: props.form.value,
    label: editForm.value.rows.map((row) => row.label).join("-"),
    key: key.value,
    required: editForm.value.required === "true" ? true : false,
    locked: props.form.locked || false,
    attributes: {
      column: editForm.value.column,
      maxRows: parseInt(editForm.value.maxRows),
      editable: props.form.attributes.editable ?? true,
    },
    rowData: editForm.value.rows.map((row) => {
      return {
        label: row.label,
        type: "input",
        value: "",
        attributes: {
          type: row.type,
          placeholder: row.placeholder,
          description: row.description,
        },
      };
    }),
    options: props.form.options,
  };

  emit("edit", data);
};

const key = computed(() => {
  // convert string to snake case
  return editForm.value.rows
    .map((row) => row.label)
    .join("-")
    .toLowerCase()
    .replace(/ /g, "_")
    .replace(/[^\w-]+/g, "");
});

watch(
  () => editForm.value,
  (val) => {
    console.log("rows", JSON.stringify(val, null, 2));
    // if (val) {
    //   const data = {
    //     id: props.form.id,
    //     type: props.form.type,
    //     value: props.form.value,
    //     label: val.map((row) => row.label).join("-"),
    //     key: key.value,
    //     required: editForm.value.required === "true" ? true : false,
    //     locked: props.form.locked || false,
    //     attributes: {
    //       column: editForm.value.column,
    //       maxRows: parseInt(editForm.value.maxRows),
    //     },
    //     rowData: val,
    //     options: props.form.options,
    //   };

    //   emit("edit", data);
    // }
  },
  { deep: true }
);

const cancel = () => {
  if (!props.form.label) {
    emit("remove", props.form);
  } else {
    emit("cancelEdit", props.form);
  }
};
</script>
<template>
  <div v-if="editForm" class="hyphen-input-dash">
    <h2>Field Settings</h2>
    <div class="hyphen-input-dash__header">
      <div class="text-hint">
        <span v-html="alert"></span><span>List field</span>
      </div>
      <p>
        Allow users dynamically add or subtract input fields, ideal for
        collecting dynamic data where the number of entries varies.
      </p>
    </div>

    <div class="hyphen-input-dash__content">
      <hyphen-select
        v-model="editForm.column"
        @changed="changeColumn"
        label="Numbe of columns"
        placeholder="Select number of columns"
        option-label="label"
        option-value="value"
        :options="[
          {
            label: '1',
            value: 1,
          },
          {
            label: '2',
            value: 2,
          },
        ]"
      />
      <hyphen-input
        v-model="editForm.maxRows"
        type="digits"
        placeholder="Maximum rows allowed"
        label="Maximum rows allowed"
      />

      <div
        class="hyphen-input-dash__row-settings"
        v-for="(row, index) in editForm.rows"
        :key="index"
      >
        <div class="tt">Column {{ numberToWords(index + 1) }}</div>
        <hyphen-select
          v-model="editForm.rows[index].type"
          label="Input Type"
          placeholder="Select a type"
          description="Type of input you want to create"
          option-label="label"
          option-value="value"
          :options="[
            {
              label: 'Text',
              value: 'text',
            },
            {
              label: 'Number',
              value: 'number',
            },
            {
              label: 'Email',
              value: 'email',
            },
            {
              label: 'Money',
              value: 'money',
            },
            {
              label: 'URL',
              value: 'url',
            },
          ]"
        />

        <hyphen-input
          v-model="editForm.rows[index].label"
          id="dash-input-label"
          placeholder="Enter label"
          label="Label"
          description="Label of input you want to create"
        />

        <hyphen-input
          v-model="editForm.rows[index].placeholder"
          id="dash-input-placeholder"
          placeholder="Enter placeholder text"
          label="Placeholder (optional)"
          description="Placeholder of input you want to create"
        />

        <hyphen-input
          v-model="editForm.rows[index].description"
          id="dash-input-description"
          placeholder="Enter description text"
          label="Description (optional)"
          description="Description of input you want to create"
        />
      </div>
    </div>
    <div class="hyphen-input-dash__action">
      <button @click="cancel" class="cancel">Cancel</button>
      <button
        @click="apply"
        v-if="canApply"
        class="apply"
        :class="{
          'apply--disabled': !canApply,
        }"
      >
        Apply
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.hyphen-input-dash {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  h2 {
    color: var(--hyphen-Blue, #19283d);
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 175% */
  }

  &__header {
    padding: 20px;
    margin: 20px 0px;
    border-radius: 4px;
    background: #f8f9fa;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.03);

    .text-hint {
      display: flex;
      align-items: center;
      gap: 10px;

      & > span:nth-child(2) {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 3px;
      }
    }

    p {
      color: var(--body-text, rgba(25, 40, 61, 0.8));
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 183.333% */
    }
  }

  &__content {
    flex: 1;
    overflow: auto;
    gap: 30px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  &__row-settings {
    .tt {
      color: var(--body-text, rgba(25, 40, 61, 0.8));
      font-weight: 600;
    }
    gap: 30px;
    display: flex;
    flex-direction: column;
  }

  &__content::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }
  &__content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
  &__content::-webkit-scrollbar-thumb {
    background-color: var(--hyphen-Blue, #19283d);
    border-radius: 10px;
  }

  &__action {
    box-sizing: border-box;
    display: flex;
    gap: 20px;
    padding: 10px 0px;
    margin-bottom: 40px;
    z-index: 99;

    button {
      flex: 1;

      padding: 15px 30px;
      border-radius: 4px;
      border: none;

      font-size: 16px;
      font-weight: 500;
      cursor: pointer;

      &.cancel {
        border-radius: 4px;
        background: var(--secondary-button, #f4f5f6);
      }

      &.apply {
        border-radius: 4px;
        color: #fff;
        background: var(--hyphen-Blue, #19283d);
      }
    }
  }
}
</style>
